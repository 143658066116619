import React from 'react';
import { Box, Stack, TextFieldProps, Typography, styled, TextField } from '@mui/material';

type SegmentFieldProps = {
  title: string;
  info: string;
  tooltipNote?: string | React.ReactNode;
  value?: string | null;
  warning?: string | null;
} & TextFieldProps;

export function SegmentField({ title, info, tooltipNote, ...props }: SegmentFieldProps) {
  const InfoBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9,
    padding: '0.8em',
    borderRadius: '0.8em',
    fontSize: '0.85rem',
    fontWeight: 500,
    background: theme.palette.tertiaryContainer.main,
    color: theme.palette.tertiaryContainer.contrastText,
    boxShadow: theme.shadows[2],
  }));
  const textFieldId = `${title}-input`;

  return (
    <Stack gap={0.5}>
      <Typography variant='labelLarge' component='label' htmlFor={textFieldId}>
        {title}
      </Typography>
      <Typography gutterBottom variant='bodyMedium'>
        {info}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        {tooltipNote && <InfoBox>{tooltipNote}</InfoBox>}
        <TextField
          id={textFieldId}
          data-testid={textFieldId}
          value={props.value || ''}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          fullWidth
          multiline
          minRows={3}
          helperText={props.warning}
          warning={(!!props.warning).toString()}
        />
      </Box>
    </Stack>
  );
}
