import React from 'react';
import { Stack, Box } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { LessonSection } from 'controllers/types';
import { LessonsLoadingIndicator } from 'components/admin/LessonBuilder/LessonsLoadingIndicator';
import { useSnackbar } from 'notistack';
import { useLessonSectionsQuery, useUpdateSection } from 'controllers/react-query';
import { SectionListItem } from './SectionListItem';
import { AddSectionDialog } from './AddToLesson/AddSectionDialog';

export function SectionList() {
  const { lessonId } = useParams();
  const { data } = useLessonSectionsQuery(lessonId);
  const lessonSections = data?.lesson_sections || [];

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateSectionPosition, isPending } = useUpdateSection();

  const reposition = (section: LessonSection, options: { direction: number }) => {
    const newPosition = section.position + options.direction;
    updateSectionPosition(
      {
        lessonSectionId: section.id,
        lessonId: section.lesson_id,
        payload: { lessonSection: { position: newPosition } },
      },
      {
        onError: err => {
          const errorTitle = `Failed to reposition section`;
          console.error(errorTitle, section.id, err.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        },
      },
    );
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={8}>
      <Stack flex='0 0 280px' gap={4} position={{ xs: 'static', sm: 'sticky' }} top={64} height='100%'>
        {isPending ? (
          <LessonsLoadingIndicator />
        ) : (
          lessonSections?.map(section => (
            <SectionListItem
              key={section.id}
              isLast={section.position === lessonSections.length}
              reposition={reposition}
              section={section}
            />
          ))
        )}
        <Box>
          <AddSectionDialog disabled={isPending} />
        </Box>
      </Stack>
      <Outlet />
    </Stack>
  );
}
