import { Card, CardActions, CardContent, IconButton, TextField } from '@mui/material';
import { Done as DoneIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { PlaylistPayload } from 'controllers/react-query/playlistsHooks';

export const PlaylistForm = ({
  playlistEdit,
  onSubmit,
}: {
  playlistEdit?: PlaylistPayload;
  onSubmit: (playlistEdit: PlaylistPayload) => void;
}) => {
  const playlistInitialState = {
    name: playlistEdit?.name || '',
    description: playlistEdit?.description || '',
  };
  const [formPlaylist, setFormPlaylist] = useState(playlistInitialState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormPlaylist(prevFormPlaylist => ({
      ...prevFormPlaylist,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formPlaylist);
  };

  return (
    <Card variant='outlined'>
      <form data-testid='playlist-form' onSubmit={handleSubmit}>
        <CardContent>
          <TextField
            fullWidth
            margin='normal'
            key='name'
            required
            data-testid='playlist-name'
            name='name'
            label='Name'
            type='text'
            value={formPlaylist.name}
            onChange={handleInputChange}
            variant='outlined'
          />

          <TextField
            fullWidth
            margin='normal'
            key='description'
            required
            data-testid='playlist-description'
            name='description'
            label='Description'
            type='text'
            value={formPlaylist.description}
            onChange={handleInputChange}
            variant='outlined'
          />
        </CardContent>
        <CardActions>
          <IconButton aria-label='submit playlist' type='submit' data-testid='submit-button'>
            <DoneIcon />
          </IconButton>
        </CardActions>
      </form>
    </Card>
  );
};
