import React from 'react';
import { FormControl, MenuItem, Select, Typography, Stack } from '@mui/material';
import { DetailedSegmentType } from 'controllers/types';

export function SegmentTypeSelect({
  segmentType = 'exploratory',
  setSegmentType,
}: {
  segmentType: DetailedSegmentType;
  setSegmentType: React.Dispatch<React.SetStateAction<DetailedSegmentType>> | ((value: string) => void);
}) {
  type SelectOption = {
    label: string;
    description: string;
  };

  type SelectOptions = {
    [key in DetailedSegmentType]?: SelectOption;
  };

  const selectOptions: SelectOptions = {
    step: {
      label: 'Lecture',
      description:
        'Present instructional material with a generated slide, or upload your own video or image. This layout is great for breaking up longer content into digestible bullets.',
    },
    question: {
      label: 'Question',
      description:
        'Present instructional material with a generated slide, or upload your own video or image. This layout is perfect for shorter content, such as a question, that deserves extra emphasis.',
    },
    multiple_choice: {
      label: 'Multiple Choice Question',
      description: 'Ask the learner a multiple choice question. Provide custom responses to each option.',
    },
    exploratory: {
      label: 'Guided Conversation',
      description:
        'An interactive conversation between the learner and an AI tutor. The AI tutor will guide the learner towards a correct answer by posing questions and providing feedback to correct misconceptions.',
    },
    reflection: {
      label: 'Reflective Conversation',
      description:
        'An interactive reflective conversation between the learner and an AI tutor. The AI tutor encourages learners to develop metacognition through wide-ranging, open-ended conversations with no "right" answer.',
    },
    assessment: {
      label: 'Assessment Conversation',
      description:
        'An interactive conversation-based assessment between the learner and an AI tutor. The AI tutor will ask questions and provide minimal to no guidance to the learner. Instructors will receive feedback regarding learner misconceptions.',
    },
  };

  return (
    <FormControl>
      <Typography id='segment-variant-label' my={2}>
        Choose a type of segment to add to this section.
      </Typography>
      <Select
        labelId='segment-variant-label'
        data-testid='segment-variant-select'
        value={segmentType}
        renderValue={() => selectOptions[segmentType]?.label}
        onChange={e => setSegmentType(e.target.value as DetailedSegmentType)}
      >
        {Object.entries(selectOptions).map(([key, option], index) => (
          <MenuItem
            value={key}
            sx={{ whiteSpace: 'wrap', maxWidth: 640 }}
            divider={index < Object.keys(selectOptions).length - 1}
          >
            <Stack py={1}>
              <Typography variant='labelLarge'>{option.label}</Typography>
              <Typography variant='bodyMedium' color='text.secondary'>
                {option.description}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
