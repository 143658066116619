import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

type CaseStudyCardProps = {
  description: string;
  quote: string;
  _path?: string;
};

export function CaseStudyCard({ description, quote, _path }: CaseStudyCardProps) {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        bgcolor: '#FFFFFF',
        px: { sm: '32px', xs: '24px' },
        py: { sm: '40px', xs: '32px' },
        borderRadius: { sm: 3, xs: 2 },
        gap: { sm: 4, xs: 2 },
      }}
    >
      <Box />
      <Typography
        variant='bodyLarge'
        sx={{ fontSize: { sm: '20px', xs: '16px' }, lineHeight: { sm: '24px', xs: '20px' } }}
      >
        {description}
      </Typography>
      <Typography variant={isMobile ? 'titleMedium' : 'titleLarge'}>{quote}</Typography>
      {/* <Row gap={2}>
        <Button href={path}>Learn more</Button>
      </Row> */}
    </Stack>
  );
}
