import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { Lesson, LessonSegment } from 'controllers/types';
import { getHumanReadableDuration } from '../../utils/displayDuration';

type Props = {
  lesson?: Lesson | null;
  lessonSegment?: LessonSegment | null;
};

export const StudentProgress = ({ lesson, lessonSegment }: Props) => {
  const [openInfo, setOpenInfo] = React.useState(false);
  const isLessonProgressAvailable = !isNil(lesson?.total_time) && !isNil(lessonSegment?.duration_completed);
  const calculateLessonProgress = () => {
    if (!lessonSegment || !lesson) {
      return undefined;
    }

    return Math.round((lessonSegment.duration_completed / lesson.total_time) * 100);
  };

  const calculateRemainingTime = () => {
    if (!lessonSegment || !lesson) {
      return undefined;
    }

    return getHumanReadableDuration(lesson.total_time - lessonSegment.duration_completed);
  };

  return (
    <Box>
      {isLessonProgressAvailable && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            px: 2,
            height: '64px',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <Button
            aria-label='shows lesson progress'
            variant='outlined'
            sx={{
              borderWidth: '1px',
              p: 0,
              m: 0,
              position: 'relative',
              display: 'inline-flex',
            }}
            onClick={() => setOpenInfo(!openInfo)}
          >
            {/* Setting size to 46 to offset border from the button. So total can match up to 48px like other buttons in that row */}
            <CircularProgress size={46} variant='determinate' value={calculateLessonProgress()} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant='labelMedium'>{`${calculateLessonProgress()}%`}</Typography>
            </Box>
          </Button>
          {openInfo && (
            <Box
              sx={{
                p: 2,
                backgroundColor: 'surface.main',
                color: 'secondaryContainer.contrastText',
                borderWidth: '1px',
                borderRadius: 5,
              }}
            >
              <Typography
                variant='bodySmall'
                sx={{
                  color: 'text.primary',
                }}
              >
                Approx {calculateRemainingTime()} remaining
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
