import { Help } from '@mui/icons-material';
import { Stack, SxProps, Typography } from '@mui/material';
import React from 'react';

type ExamplePromptCardProps = {
  prompt: string;
  id: string;
  onClick: (prompt: string) => void;
  sx?: SxProps;
};

export function ExamplePromptCard({ prompt, id, onClick, sx }: ExamplePromptCardProps) {
  return (
    <Stack
      sx={{
        p: 2,
        bgcolor: '#FFFFFFCC',
        gap: 1,
        borderRadius: 2,
        borderColor: 'transparent',
        cursor: 'pointer',
        textAlign: 'left',
        '&:hover': { borderColor: 'primary.main' },
        ...sx,
      }}
      component='button'
      type='button'
      aria-labelledby={id}
      onClick={() => onClick(prompt)}
    >
      <Help color='primary' />
      <Typography variant='labelMedium' color='primary' component='span'>
        Example prompt
      </Typography>
      <Typography variant='bodyMedium' color='primaryContainer.contrastText' id={id}>
        {prompt}
      </Typography>
    </Stack>
  );
}
