import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import React from 'react';

export function KyronMenuItem<C extends React.ElementType>({
  icon,
  label,
  onClick,
  closeMenu,
  ...props
}: MenuItemProps<C, { component?: C; icon: React.ReactNode; label: React.ReactNode }> & { closeMenu?: () => void }) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    closeMenu?.();
  };

  return (
    <MenuItem {...props} onClick={handleClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
