import { Box, Button, IconButton, Input, Link, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Row } from 'components/Row/Row';
import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import { KyronLogo } from 'components/KyronLogo';
import { useIntercom } from 'react-use-intercom';
import { ExamplePromptCard } from './components/ExamplePromptCard';
import { ValuePropBlock } from './components/ValuePropBlock';
import { CaseStudyCard } from './components/CaseStudyCard';
import { HomepageSection } from './components/HomepageSection';
import { HomepageWrapper } from './components/HomepageWrapper';
import { HomepageHeadline } from './components/HomepageHeadline';
import { CosmicGradient } from './components/CosmicGradient';
import { MarketingHeader } from './components/MarketingHeader';

const valueProps = [
  {
    headline: 'Accelerate Course Development with AI',
    subhead: 'Accelerated Development',
    description:
      'Revolutionize entire course creation by automating the generation of interactive, pedagogically sound courses. Achieve significant time and cost savings, enabling your institution to offer high-quality educational content swiftly and affordably.',
  },
  {
    headline: 'Truly Interactive Activities',
    subhead: 'Engaging Interactivity',
    description:
      'Our platform offers a personalized and expertly managed learning journey anchored by guided discussions. By facilitating meaningful dialogues between learners and AI, we enhance engagement, improve learning efficacy, and ensure a safe educational environment.',
  },
  {
    headline: 'Gain Insight and Drive Efficiency',
    subhead: 'Analytics',
    description:
      'Leverage advanced analytics to assess discussions and track misconceptions at both individual and cohort levels. Our platform provides actionable insights that enhance teaching strategies and improve educational outcomes.',
  },
];

const caseStudies = [
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
  {
    description: 'Western Governors University created mini-courses to reteach exam concepts before exams.',
    quote: '“Students who used Kyron [courses] before exams saw a 64% increase in likelihood of passing the exam.”',
    path: '/case-study',
  },
];

export function MarketingHome() {
  const { showSpace } = useIntercom();
  const [demoPrompt, setDemoPrompt] = React.useState('');
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const footerLinks = [
    {
      category: 'Product',
      links: [
        { label: 'Help', onClick: () => showSpace('help') },
        { label: 'Pricing', href: '/pricing' },
        { label: 'Contact us', href: '/contact-us' },
      ],
    },
    {
      category: 'Company',
      links: [
        { label: 'Team', href: '/team' },
        { label: 'Terms of service', href: '/terms_of_service' },
        { label: 'Privacy policy', href: '/privacy_policy' },
      ],
    },
    {
      category: 'Follow us',
      links: [
        { label: 'Blog', href: 'https://blog.kyronlearning.com/' },
        { label: 'LinkedIn', href: 'https://www.linkedin.com/company/kyronlearning/' },
        { label: 'Twitter', href: 'https://twitter.com/kyronlearning' },
        { label: 'Instagram', href: 'https://www.instagram.com/kyronlearning/' },
      ],
    },
  ];

  const examplePrompts = [
    'Differences between Agile and Waterfall project management',
    'How Electric Motors Transform Energy into Movement',
    'What Makes a Parachute Work?',
  ];

  return (
    <>
      <MarketingHeader />

      <HomepageSection pt={isMobile ? '72px' : '90px'} pb={isMobile ? '480px' : '610px'} bgcolor='#FFF5EC'>
        <HomepageWrapper gap={isMobile ? '72px' : '90px'}>
          <Stack gap='10px' maxWidth='720px'>
            <Typography
              variant='displayLarge'
              sx={{ fontSize: { sm: '72px', xs: '40px' }, lineHeight: { sm: '78px', xs: '48px' } }}
            >
              Create engaging online courses
            </Typography>
            <Typography
              variant='displayMedium'
              component='h2'
              sx={{
                fontSize: { sm: '48px', xs: '32px' },
                lineHeight: { sm: '56px', xs: '40px' },
                fontWeight: 'normal',
              }}
            >
              10x faster and 10x cheaper
            </Typography>
          </Stack>

          <Stack gap={2} maxWidth='640px'>
            <Typography
              variant='bodyLarge'
              sx={{
                fontSize: { sm: '24px', xs: '19px' },
                lineHeight: { sm: '32px', xs: '27px' },
                color: 'text.secondary',
              }}
            >
              With the power of AI you can create engaging online courses of any size or scope with the push of a
              button. Simple to customize. True interactivity with adaptive learning activities.
            </Typography>
            <Row gap={2}>
              <Button>Try it for free</Button>
            </Row>
          </Stack>

          <Typography variant='titleLarge' sx={{ color: 'text.secondary' }}>
            Preview some of our AI-powered interactive courses.
          </Typography>
        </HomepageWrapper>

        <Stack
          gap={isMobile ? 2 : 3}
          mt={2}
          sx={{ position: 'absolute', width: '100vw', bottom: '90px', left: '0', zIndex: 2 }}
        >
          <Row gap={isMobile ? 2 : 3} sx={{ position: 'relative', left: '-80px' }}>
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
          </Row>
          <Row gap={isMobile ? 2 : 3} sx={{ position: 'relative', left: '-305px' }}>
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
            <Box
              sx={{
                aspectRatio: '16/9',
                height: { sm: '240px', xs: '180px' },
                bgcolor: 'primary.main',
                borderRadius: 3,
              }}
            />
          </Row>
        </Stack>

        <CosmicGradient sx={{ position: 'absolute', left: 'calc(50% + 145px)', top: '159px', zIndex: 0 }} />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '130px'} pb={isMobile ? '80px' : '130px'} bgcolor='#FAFCFF'>
        <HomepageWrapper maxWidth='920px' gap={isMobile ? '64px' : '130px'}>
          <HomepageHeadline subhead='Try it for free'>
            Create a course in minutes. Customize to your liking.
          </HomepageHeadline>

          <Stack gap={3}>
            <Input
              type='text'
              name='learning_objective'
              placeholder='What would you like to teach today?'
              value={demoPrompt}
              onChange={e => setDemoPrompt(e.target.value)}
              disableUnderline
              sx={{
                height: '72px',
                fontSize: '20px',
                borderRadius: 3,
                bgcolor: '#FFFFFF',
                border: 'none',
                px: { sm: 3, xs: 2 },
                gap: 1,
                boxShadow:
                  '0px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 0px 1.1px 1.8px -0.8px rgba(162, 147, 169, 0.34), 0px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34), 0px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34)',
              }}
              endAdornment={
                <IconButton color='primary' variant='filled' disabled={demoPrompt === ''}>
                  <ArrowForward />
                </IconButton>
              }
            />

            <Row
              sx={{
                gap: { sm: 3, xs: 0 },
                alignItems: 'stretch',
                overflowX: { sm: 'hidden', xs: 'auto' },
                mx: { sm: '0', xs: '-16px' },
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              {examplePrompts.map((prompt, index) => (
                <ExamplePromptCard
                  prompt={prompt}
                  id={index.toString()}
                  onClick={setDemoPrompt}
                  sx={{
                    flex: { sm: 1, xs: '0 0 75%' },
                    mr: { sm: '0', xs: '16px' },
                    ':first-child': { ml: { sm: '0', xs: '16px' } },
                  }}
                />
              ))}
            </Row>
          </Stack>
        </HomepageWrapper>
        <CosmicGradient
          variant='nebula'
          sx={{ position: 'absolute', left: 'calc(50% - 410px)', bottom: '-320px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '160px'} pb={isMobile ? '80px' : '160px'} bgcolor='#FAF7F9'>
        <HomepageWrapper gap={isMobile ? '64px' : '130px'}>
          <HomepageHeadline>Why Kyron courses?</HomepageHeadline>

          {valueProps.map(({ headline, subhead, description }) => (
            <ValuePropBlock headline={headline} subhead={subhead}>
              {description}
            </ValuePropBlock>
          ))}
        </HomepageWrapper>
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% - 1000px)', top: '-100px', zIndex: 0 }}
        />
        <CosmicGradient
          variant='forest'
          sx={{ position: 'absolute', left: 'calc(50% + 300px)', bottom: '-100px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '130px'} pb={isMobile ? '80px' : '130px'} bgcolor='#03317B'>
        <HomepageWrapper gap='90px'>
          <HomepageHeadline color='#E5E1E6' subhead='Partners and Case-Studies'>
            Don’t take our word for it
          </HomepageHeadline>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', gap: '32px' }}>
            {caseStudies.map(({ description, quote }) => (
              <CaseStudyCard description={description} quote={quote} />
            ))}
          </Box>
        </HomepageWrapper>

        <CosmicGradient
          variant='nebula'
          sx={{ position: 'absolute', left: 'calc(50% - 410px)', top: '-400px', zIndex: 0 }}
        />
      </HomepageSection>

      <HomepageSection pt={isMobile ? '80px' : '160px'} pb={isMobile ? '80px' : '160px'} bgcolor='#460052'>
        <HomepageWrapper maxWidth='800px' gap='120px'>
          <Stack sx={{ gap: 2, maxWidth: '720px', m: '0 auto', textAlign: 'center' }}>
            <Typography
              variant='displayLarge'
              sx={{ color: '#FFDED1', fontSize: { sm: '72px', xs: '40px' }, lineHeight: { sm: '78px', xs: '48px' } }}
            >
              Create engaging online courses
            </Typography>
            <Typography
              variant='headlineLarge'
              sx={{
                color: '#F7D0FC',
                fontSize: { sm: '48px', xs: '32px' },
                lineHeight: { sm: '56px', xs: '40px' },
                fontWeight: 'normal',
              }}
            >
              10x faster and 10x cheaper
            </Typography>
          </Stack>

          <Row
            sx={{
              gap: { sm: 3, xs: 6 },
              alignItems: { sm: 'flex-start', xs: 'center' },
              flexDirection: { sm: 'row', xs: 'column' },
            }}
          >
            <Box flex={isMobile ? 1 : '0 0 240px'}>
              <KyronLogo fill='#EAB9D1' height='20px' />
            </Box>

            {footerLinks.map(({ category, links }) => (
              <Stack gap={isMobile ? 1 : 4} flex={isMobile ? '0 0 auto' : 1} textAlign={isMobile ? 'center' : 'left'}>
                <Typography variant='titleMedium' color='#EAB9D1'>
                  {category}
                </Typography>
                <Stack gap={isMobile ? '8px' : '12px'}>
                  {links.map(({ label, href, onClick }) => {
                    if (onClick) {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                          variant='bodyLarge'
                          color='#E5E1E6'
                          onClick={onClick}
                          sx={{ cursor: 'pointer' }}
                          tabIndex={0}
                        >
                          {label}
                        </Link>
                      );
                    }
                    return (
                      <Link variant='bodyLarge' href={href} color='#E5E1E6'>
                        {label}
                      </Link>
                    );
                  })}
                </Stack>
              </Stack>
            ))}
          </Row>

          <Typography variant='bodyMedium' textAlign='center' color='#F4AEFF'>
            © 2021 Kyron Learning. All rights reserved.
          </Typography>
        </HomepageWrapper>

        <CosmicGradient
          variant='ocean'
          sx={{ position: 'absolute', left: 'calc(50% - 526px)', top: '-280px', zIndex: 0 }}
        />
      </HomepageSection>
    </>
  );
}
