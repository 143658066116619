// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`EditSegmentField when there is just a step segment should render the component with default values 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 4px;
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0.35em;
}

.emotion-4 {
  position: relative;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
}

.emotion-6 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px 0 5px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 16.5px 14px;
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-6:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-6:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-6.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-6.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-7 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 0;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: auto;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  resize: none;
  padding-top: 0;
  padding: 0;
}

.emotion-7::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7:focus {
  outline: 0;
}

.emotion-7:invalid {
  box-shadow: none;
}

.emotion-7::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-7.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-7:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-7:-webkit-autofill {
  border-radius: inherit;
}

.emotion-9 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-10 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-12 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.emotion-13 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.emotion-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.emotion-15 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: -11px;
  margin-right: 16px;
  color: #1C1B1FB3;
}

.emotion-15.Mui-disabled {
  cursor: default;
}

.emotion-15 .MuiFormControlLabel-label.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-16 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  padding: 9px;
  border-radius: 50%;
  color: #1C1B1FB3;
}

.emotion-16::-moz-focus-inner {
  border-style: none;
}

.emotion-16.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-16 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-16:hover {
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-16:hover {
    background-color: transparent;
  }
}

.emotion-16.Mui-checked {
  color: #4B35FF;
}

.emotion-16.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-17 {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.emotion-18 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.emotion-19 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.emotion-20 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  left: 0;
  position: absolute;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-21 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-22 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emotion-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-29 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  left: 0;
  position: absolute;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1) 0ms;
}

.emotion-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-33 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  min-width: 156px;
}

.emotion-33::-moz-focus-inner {
  border-style: none;
}

.emotion-33.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-33 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-33:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-33:hover {
    background-color: transparent;
  }
}

.emotion-33.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-33:hover {
  box-shadow: none;
}

.emotion-33.Mui-focusVisible {
  box-shadow: none;
}

.emotion-33:active {
  box-shadow: none;
}

.emotion-33.Mui-disabled {
  box-shadow: none;
}

.emotion-34 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-34>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-35 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-36 {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

<div
    class="MuiStack-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <label
        class="MuiTypography-root MuiTypography-labelLarge emotion-2"
        for="My Title-input"
      >
        My Title
      </label>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium MuiTypography-gutterBottom emotion-3"
      >
        Some Info
      </p>
      <div
        class="MuiBox-root emotion-4"
      >
        <div
          class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-5"
          data-testid="My Title-input"
          warning="false"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline emotion-6"
          >
            <textarea
              aria-invalid="false"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-7"
              id="My Title-input"
              style="height: 0px; overflow: hidden;"
            >
              Segment Description
            </textarea>
            <textarea
              aria-hidden="true"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline emotion-7"
              readonly=""
              style="visibility: hidden; position: absolute; overflow: hidden; height: 0px; top: 0px; left: 0px; transform: translateZ(0); padding-top: 0px; padding-bottom: 0px; width: 100%;"
              tabindex="-1"
            />
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-9"
            >
              <legend
                class="emotion-10"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div
      class="MuiBox-root emotion-11"
    >
      <h4
        class="MuiTypography-root MuiTypography-titleSmall emotion-12"
        id="media-selection-radio-buttons-label"
      >
        Media
      </h4>
      <div
        class="MuiFormControl-root emotion-13"
      >
        <div
          aria-labelledby="media-selection-radio-buttons-label"
          class="MuiFormGroup-root MuiRadioGroup-root emotion-14"
          role="radiogroup"
        >
          <label
            class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd emotion-15"
          >
            <span
              class="MuiButtonBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall PrivateSwitchBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall Mui-checked MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall emotion-16"
            >
              <input
                aria-label="default generated kyron video"
                checked=""
                class="PrivateSwitchBase-input emotion-17"
                name="media-selection-radio-buttons"
                type="radio"
                value="kyron_video"
              />
              <span
                class="emotion-18"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-19"
                  data-testid="RadioButtonUncheckedIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                </svg>
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-20"
                  data-testid="RadioButtonCheckedIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"
                  />
                </svg>
              </span>
              <span
                class="MuiTouchRipple-root emotion-21"
              />
            </span>
            <span
              class="MuiTypography-root MuiTypography-bodyMedium MuiFormControlLabel-label emotion-22"
            >
              Slide
            </span>
          </label>
          <div
            class="MuiStack-root emotion-23"
          >
            <label
              class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd emotion-15"
            >
              <span
                class="MuiButtonBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall PrivateSwitchBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall emotion-16"
              >
                <input
                  aria-label="user video"
                  class="PrivateSwitchBase-input emotion-17"
                  name="media-selection-radio-buttons"
                  type="radio"
                  value="user_video"
                />
                <span
                  class="emotion-18"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-19"
                    data-testid="RadioButtonUncheckedIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                    />
                  </svg>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-29"
                    data-testid="RadioButtonCheckedIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"
                    />
                  </svg>
                </span>
                <span
                  class="MuiTouchRipple-root emotion-21"
                />
              </span>
              <span
                class="MuiTypography-root MuiTypography-bodyMedium MuiFormControlLabel-label emotion-22"
              >
                Video
              </span>
            </label>
            <div
              class="MuiStack-root emotion-32"
            >
              <label
                aria-disabled="true"
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation Mui-disabled MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-33"
                role="button"
                tabindex="-1"
              >
                <span
                  class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-34"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-35"
                    data-testid="UploadFileIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                    />
                  </svg>
                </span>
                Choose video
                <input
                  accept="video/mp4, video/quicktime, video/webm"
                  class="emotion-36"
                  data-testid="file-uploader-input"
                  disabled=""
                  type="file"
                />
              </label>
            </div>
          </div>
          <div
            class="MuiStack-root emotion-23"
          >
            <label
              class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd emotion-15"
            >
              <span
                class="MuiButtonBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall PrivateSwitchBase-root MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall MuiRadio-root MuiRadio-colorPrimary MuiRadio-sizeSmall emotion-16"
              >
                <input
                  aria-label="user image"
                  class="PrivateSwitchBase-input emotion-17"
                  name="media-selection-radio-buttons"
                  type="radio"
                  value="user_image_with_audio"
                />
                <span
                  class="emotion-18"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-19"
                    data-testid="RadioButtonUncheckedIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                    />
                  </svg>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-29"
                    data-testid="RadioButtonCheckedIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"
                    />
                  </svg>
                </span>
                <span
                  class="MuiTouchRipple-root emotion-21"
                />
              </span>
              <span
                class="MuiTypography-root MuiTypography-bodyMedium MuiFormControlLabel-label emotion-22"
              >
                Image
              </span>
            </label>
            <div
              class="MuiStack-root emotion-32"
            >
              <label
                aria-disabled="true"
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation Mui-disabled MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation upload-button emotion-33"
                role="button"
                tabindex="-1"
              >
                <span
                  class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-34"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-35"
                    data-testid="UploadFileIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8zm4 18H6V4h7v5h5zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
                    />
                  </svg>
                </span>
                Choose image
                <input
                  accept="image/png, image/jpeg, image/jpg"
                  class="emotion-36"
                  data-testid="file-uploader-input"
                  disabled=""
                  type="file"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
