import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Row } from 'components/Row/Row';
import React from 'react';

type ValuePropBlockProps = {
  headline: string;
  subhead: string;
  children: React.ReactNode;
};

export function ValuePropBlock({ headline, subhead, children }: ValuePropBlockProps) {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Row sx={{ gap: { sm: 8, xs: 3 }, alignItems: 'flex-start', flexDirection: { sm: 'row', xs: 'column' } }}>
      <Stack flex={1} gap={isMobile ? 1 : 3}>
        <Typography variant='bodyLarge' sx={{ fontSize: { sm: '24px', xs: '18px' }, color: 'text.secondary' }}>
          {subhead}
        </Typography>
        <Typography variant={isMobile ? 'headlineLarge' : 'displayMedium'} component='h3'>
          {headline}
        </Typography>
        <Typography
          variant='bodyLarge'
          sx={{ fontSize: { sm: '20px', xs: '18px' }, lineHeight: { sm: '28px', xs: '24px' } }}
        >
          {children}
        </Typography>
      </Stack>
      <Box
        sx={{
          aspectRatio: '16/9',
          flex: 1,
          width: { sm: 'auto', xs: '100%' },
          bgcolor: 'surfaceContainer.main',
          borderRadius: '24px',
          boxShadow:
            '0px 143px 40px 0px rgba(80, 0, 117, 0.00), 0px 91px 37px 0px rgba(80, 0, 117, 0.01), 0px 51px 31px 0px rgba(80, 0, 117, 0.02), 0px 23px 23px 0px rgba(80, 0, 117, 0.04), 0px 6px 13px 0px rgba(80, 0, 117, 0.05)',
        }}
      />
    </Row>
  );
}
