import React, { useState } from 'react';
import { Button, Container, GlobalStyles, IconButton, Stack, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useGenerateEntireLesson, useStructureLearningObjectives } from 'controllers/react-query';
import { GenerateQuestionsPayload } from 'controllers/react-query/lessonGenerationHooks';
import { AppBar } from 'components/AppBar/AppBar';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { CreateLessonForm } from './CreateLessonForm';
import { ValidationModal } from './ValidationModal';

const lessonBuilderGlobalStyles = (
  <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
);

export function LessonBuilder() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isPending: isCreatingLesson, mutate: createLesson } = useGenerateEntireLesson();
  const {
    isPending: isStructuringObjectives,
    error: structuringError,
    mutate: structureLearningObjectives,
  } = useStructureLearningObjectives();
  const [structuredObjectives, setStructuredObjectives] = useState<string[]>([]);
  const [validationModalOpen, setValidationModalOpen] = useState(false);

  const [createLessonPayload, setCreateLessonPayload] = useState<GenerateQuestionsPayload>({
    lessonTitle: '',
    lessonObjective: '',
    audience: '',
    structuredLearningObjectives: [],
    signedMaterialIds: [],
    questionType: null,
    activityTemplate: 'lesson',
    ttsVoiceId: 'alloy',
  });

  const { user } = useUserContext();
  const { lessonId } = useParams<{ lessonId: string }>();
  function handleCreateLesson() {
    createLesson(
      { payload: { ...createLessonPayload, structuredLearningObjectives: structuredObjectives } },
      {
        onSuccess: lesson => {
          navigate(`/studio/${lesson.id}`);
          enqueueSnackbar(`Your lesson ${lesson.name} is being created. This may take a few minutes.`, {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(`Failed to create lesson ${createLessonPayload.lessonTitle}`, { variant: 'error' });
        },
      },
    );
  }

  function getStructuredObjectives() {
    structureLearningObjectives(
      { payload: createLessonPayload },
      {
        onSuccess: objectives => {
          setStructuredObjectives(objectives);
        },
        onError: () => {
          enqueueSnackbar(`Failed to structure learning objectives for ${createLessonPayload.lessonTitle}`, {
            variant: 'error',
          });
        },
      },
    );
  }

  const approveButton = (
    <Button
      startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
      onClick={() => {
        handleCreateLesson();
        KyronEvents.sendEvent('Clicked Create Lesson - Approval Modal', {
          lesson_title: createLessonPayload.lessonTitle,
          lesson_id: lessonId,
          user_id: user?.id || 'anonymous',
        });
      }}
      disabled={isStructuringObjectives || isCreatingLesson}
    >
      Approve
    </Button>
  );

  return (
    <>
      {lessonBuilderGlobalStyles}
      <>
        <AppBar
          title='Create new lesson'
          navButton={
            <IconButton to='/studio/library' component={Link} color='inherit'>
              <ArrowBack />
            </IconButton>
          }
          sx={{ bgcolor: 'background.paper' }}
        />

        <Container maxWidth='md' sx={{ py: { xs: 2, sm: 2 } }}>
          <Stack gap={3} sx={{ bgcolor: 'background.default', borderRadius: 3, p: 3, boxShadow: 3 }}>
            <Typography variant='headlineSmall'>Create new lesson</Typography>
            <Typography variant='bodyMedium' color='text.secondary'>
              Use AI to generate a new interactive Kyron lesson. Fill in the fields below and we will take care of the
              rest.
            </Typography>

            <CreateLessonForm
              shouldShowAdditionalMaterialUpload
              promptParams={createLessonPayload}
              setPromptParams={setCreateLessonPayload}
            />

            <Stack direction='row' justifyContent='space-between'>
              <Button to='/studio' component={Link} variant='text'>
                Cancel
              </Button>
              <Button
                aria-label='Create Lesson Button'
                startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
                onClick={() => {
                  getStructuredObjectives();
                  setValidationModalOpen(true);
                  KyronEvents.sendEvent('Clicked Create Lesson', {
                    lesson_title: createLessonPayload.lessonTitle,
                    lesson_id: lessonId,
                    user_id: user?.id || 'anonymous',
                  });
                }}
                disabled={
                  !createLessonPayload.audience ||
                  !createLessonPayload.lessonObjective ||
                  !createLessonPayload.lessonTitle ||
                  isCreatingLesson
                }
              >
                Create Lesson
              </Button>
            </Stack>
          </Stack>
        </Container>

        <ValidationModal
          open={validationModalOpen}
          onClose={() => setValidationModalOpen(false)}
          approveButton={approveButton}
          loading={isStructuringObjectives}
          error={structuringError}
          objectives={structuredObjectives}
        />
      </>
    </>
  );
}
