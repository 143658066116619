import { Playlist, Pagination } from 'controllers/types';
import { keepPreviousData, useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';

export type PlaylistPayload = Omit<Playlist, 'id' | 'playlist_items' | 'updated_at'>;

export const useGetLibraryPlaylists = ({
  page = 1,
  perPage = 25,
  search,
}: { page?: number; perPage?: number; search?: string } = {}) => {
  const searchQueryStr = search ? `&q=${search}` : '';
  return useKyronQuery<{ playlists: Playlist[]; meta: Pagination }>(
    `/playlists?page=${page}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: [`/playlists`, { page, perPage, ...(search ? { search } : {}) }],
      placeholderData: keepPreviousData,
    },
  );
};

export const usePlaylistQuery = (playlistId?: number) =>
  useKyronQuery<Playlist>(`/playlists/${playlistId}`, {
    queryKey: ['/playlists', playlistId],
    enabled: !!playlistId,
  });

export const useCreatePlaylist = () =>
  useKyronMutationV2<{ payload: PlaylistPayload }, Playlist>(`/playlists`, {
    invalidates: ['/playlists'],
  });

export const useUpdatePlaylist = () =>
  useKyronMutationV2<{ playlistId: number; payload: PlaylistPayload }, Playlist>(`/playlists/:playlistId`, {
    method: 'PATCH',
    invalidates: ['/playlists'],
  });
