import React, { useState } from 'react';
import { Button, Stack, DialogContent, DialogActions, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LessonSection, DetailedSegmentType } from 'controllers/types';
import { useCreateLessonSegment } from 'controllers/react-query';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useUserContext } from 'components/UserContext';
import { SegmentTypeSelect } from './SegmentTypeSelect';

type AddSegmentDialogProps = {
  section: LessonSection;
  onClose: () => void;
};

export function AddSegmentDialog({ section, onClose }: AddSegmentDialogProps) {
  const { user } = useUserContext();
  const [segmentVariant, setSegmentVariant] = useState<DetailedSegmentType>('exploratory');
  const { mutate: createSegment, isPending, error } = useCreateLessonSegment();
  const navigate = useNavigate();

  const { lesson_id: lessonId, id: sectionId } = section;

  function handleCreateSegment() {
    KyronEvents.sendEvent(`${segmentVariant} Segment Created`, {
      lesson_id: lessonId,
      user_id: user?.id || 'anonymous',
    });
    createSegment(
      { lessonId, payload: { sectionId, segmentVariant } },
      {
        onSuccess: newSegment => {
          navigate(`/studio/${newSegment.lesson_id}/segments/${newSegment.id}`);
          onClose();
        },
      },
    );
  }

  return (
    <>
      <DialogContent>
        <Stack gap={2}>
          <SegmentTypeSelect segmentType={segmentVariant} setSegmentType={setSegmentVariant} />
          {error && <Alert severity='error'>{error.message}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='text'>
          Cancel
        </Button>
        <Button onClick={handleCreateSegment} disabled={isPending}>
          Add Segment
        </Button>
      </DialogActions>
    </>
  );
}
