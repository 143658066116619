import { Box, SxProps } from '@mui/material';
import React from 'react';

type CosmicGradientProps = {
  variant?: 'sunset' | 'nebula' | 'forest' | 'ocean';
  sx?: SxProps;
};

export function CosmicGradient({ variant = 'sunset', sx }: CosmicGradientProps) {
  if (variant === 'nebula') {
    return (
      <Box width='820px' height='938px' sx={{ position: 'relative', ...sx }}>
        <Box
          sx={{
            width: '820px',
            height: '820px',
            borderRadius: '820px',
            background:
              'linear-gradient(180deg, #00C2FF00 0%, #3E9CF040 25%, #7E76E180 50%, #BF50D2BF 75%, #FF29C3FF 100%)',
            filter: 'blur(100px)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Box
          sx={{
            width: '394px',
            height: '559px',
            borderRadius: 0,
            background: 'linear-gradient(180deg, rgba(24, 75, 255, 0.00) 0%, #174AFF 100%)',
            filter: 'blur(100px)',
            position: 'absolute',
            bottom: 0,
            left: '213px',
          }}
        />
      </Box>
    );
  }

  if (variant === 'forest') {
    return (
      <Box width='728px' height='708px' sx={{ position: 'relative', ...sx }}>
        <Box
          sx={{
            width: '570px',
            height: '570px',
            borderRadius: '570px',
            background: 'linear-gradient(0deg, #00FF4700 0%, #00FF85FF 100%)',
            filter: 'blur(150px)',
            position: 'absolute',
            top: 0,
            right: '0px',
          }}
        />

        <Box
          sx={{
            width: '624px',
            height: '624px',
            borderRadius: '624px',
            background: 'linear-gradient(135deg, #00FFF0FF 0%, #7E819F80 50%, #FF004D 100%)',
            filter: 'blur(200px)',
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
        />
      </Box>
    );
  }

  if (variant === 'ocean') {
    return (
      <Box width='1045px' height='820px' sx={{ position: 'relative', ...sx }}>
        <Box
          sx={{
            width: '820px',
            height: '820px',
            borderRadius: '820px',
            background: 'linear-gradient(0deg, #42DDFF 0%, #1170FF80 100%)',
            filter: 'blur(200px)',
            position: 'absolute',
            top: 0,
            left: '116px',
          }}
        />

        <Box
          sx={{
            width: '404px',
            height: '404px',
            borderRadius: '404px',
            background: 'linear-gradient(135deg, #F22FB0 0%, #F45C6B80 50%, #F58A2580 100%)',
            filter: 'blur(100px)',
            position: 'absolute',
            top: '60px',
            right: 0,
          }}
        />

        <Box
          sx={{
            width: '232px',
            height: '232px',
            borderRadius: '232px',
            background: 'linear-gradient(135deg, #7D40FF 0%, #BA6591 50%, #F58A2580 100%)',
            filter: 'blur(80px)',
            position: 'absolute',
            bottom: '74px',
            left: 0,
          }}
        />
      </Box>
    );
  }

  return (
    <Box width='808.051px' height='921px' sx={{ position: 'relative', ...sx }}>
      <Box
        sx={{
          width: '778.615px',
          height: '869.068px',
          borderRadius: '869.068px',
          background: 'linear-gradient(180deg, #FF7BCA 0%, rgba(255, 197, 111, 0.46) 100%)',
          filter: 'blur(100px)',
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          width: '569.718px',
          height: '635.903px',
          borderRadius: '635.903px',
          background: 'linear-gradient(180deg, #F22FB0 0%, rgba(245, 138, 37, 0.30) 100%)',
          filter: 'blur(50px)',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 1,
        }}
      />
    </Box>
  );
}
