import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { VideoPreview } from 'components/LessonSegmentEditor/VideoPreview';

type SegmentMediaDisplayProps = {
  isPending: boolean;
  videoUrl?: string;
};

export function SegmentMediaDisplay({ isPending, videoUrl }: SegmentMediaDisplayProps) {
  const getMediaComponent = () => {
    if (isPending) {
      return <Skeleton data-testid='video-skeleton' variant='rectangular' sx={{ height: '100%', width: '100%' }} />;
    }
    // Placeholder for when there is no video
    if (!videoUrl) {
      return <Box sx={{ bgcolor: 'primaryContainer.main', height: '100%', width: '100%' }} />;
    }

    return <VideoPreview url={videoUrl} key={videoUrl} start={0} />;
  };

  return (
    <Box
      data-testid='segment-media-display'
      sx={{
        flex: '1',
        aspectRatio: '16/9',
        borderRadius: 3,
        overflow: 'hidden',
        minWidth: '200px',
        position: 'relative',
      }}
    >
      {getMediaComponent()}
    </Box>
  );
}
