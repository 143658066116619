import { Stack, styled } from '@mui/material';

type HomepageWrapperProps = {
  gap?: string;
  maxWidth?: string;
};

export const HomepageWrapper = styled(Stack)(({ gap = '130px', maxWidth = '1200px' }: HomepageWrapperProps) => ({
  gap,
  maxWidth,
  margin: '0 auto',
  position: 'relative',
  zIndex: 2,
}));
