const isDev = process.env.NODE_ENV === 'development';

/**
 * A utility to log warnings and errors only in development mode.
 */
export const devConsole = {
  warn: isDev ? console.warn : () => null,
  error: isDev ? console.error : () => null,
  debug: isDev ? console.debug : () => null,
};
