import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, DialogActions, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { usePublishLesson, useLessonValidateQuery, useRegenerateAllLessonVideos } from 'controllers/react-query';
import { PublishVisibility, SegmentMedia } from 'controllers/types';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LockOpenOutlined, LockOutlined, Refresh } from '@mui/icons-material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useModal } from 'components/utils/ModalContext';
import { useFeatures } from 'components/FeaturesContext';

// defined in LessonsController#validate
enum ValidationErrors {
  MISSING_VIDEOS = 'Some segment videos are missing',
  UNNORMALIZED_VIDEOS = 'Some segment videos are not normalized',
  NO_START_SEGMENTS = 'No Start Segment exists',
  MULTIPLE_START_SEGMENTS = 'Multiple Start Segments exist',
  NO_END_SEGMENTS = 'At least 1 End Segment exists',
  UNUSED_SEGMENTS = 'Some segments are not used',
}

type VisibilityOptionType = {
  id: number;
  type: PublishVisibility;
  icon: JSX.Element;
  title: string;
  description: string;
};

const VisibilityOptions: VisibilityOptionType[] = [
  {
    id: 1,
    type: PublishVisibility.PUBLIC,
    icon: <LockOpenOutlined />,
    title: 'Public',
    description:
      'The lesson is available publicly in the Marketplace and in your public Channel. You and anyone else can assign this lesson.',
  },
  {
    id: 2,
    type: PublishVisibility.PRIVATE,
    icon: <LockOutlined />,
    title: 'Private',
    description: 'The lesson is available to you and members of your organization to share and assign.',
  },
];

export function PublishVisibilityOptions({
  visibility,
  onUpdate,
}: {
  visibility: PublishVisibility;
  onUpdate: (visibility: PublishVisibility) => void;
}) {
  return (
    <Stack gap={2}>
      {VisibilityOptions.map((option: VisibilityOptionType) => (
        <Grid2
          key={option.id}
          container
          padding={2}
          columnSpacing={2}
          onClick={() => onUpdate(option.type)}
          sx={{
            border: 1,
            borderRadius: 1,
            borderColor: 'divider',
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.6,
              bgcolor: 'primaryContainer.main',
            },
            bgcolor: visibility === option.type ? 'primaryContainer.main' : 'default',
          }}
        >
          <Grid2 xs={1} display='flex' alignItems='center' justifyContent='center' color='primary.main'>
            {option.icon}
          </Grid2>
          <Grid2 xs={11} display='flex'>
            <Stack>
              <Typography variant='titleSmall'>{option.title}</Typography>
              <Typography variant='bodyMedium'>{option.description}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      ))}
    </Stack>
  );
}

function GenerateMissingVideosButton({ lessonId }: { lessonId?: number }) {
  const { openModal } = useModal();
  const { pathname } = useLocation();
  // HACK(derick): get segmentId from pathname because this component is not on a route that supports useParams for segmentId
  // once we refactor Editor state to use a more global lesson/segment context, we should be able to get the segment from context
  const segmentId = pathname.match(/segments\/(\d+)/)?.[1];
  const { synthesia_video_generation: isSynthesiaEnabled } = useFeatures();
  const { mutate: regenerateAllLessonVideos } = useRegenerateAllLessonVideos();

  if (!lessonId || !segmentId) {
    return null;
  }

  if (isSynthesiaEnabled) {
    return (
      <Button
        color='error'
        variant='outlined'
        startIcon={<Refresh />}
        onClick={() => {
          openModal({
            id: 'generate-missing-videos-modal',
            title: 'Generate Missing Videos',
            content:
              'Are you sure you want to generate the missing videos for this lesson? This may take a few minutes and cannot be undone.',
            onConfirm: () => {
              regenerateAllLessonVideos({
                lessonId,
                segmentId,
                payload: {
                  mediaTypesToSkip: [SegmentMedia.USER_IMAGE_WITH_AUDIO, SegmentMedia.USER_VIDEO],
                  skipExisting: true,
                },
              });
            },
          });
        }}
      >
        Generate Missing Videos
      </Button>
    );
  }
}

function PublishStudioLessonDialog({ lessonId, onClose }: { lessonId?: number; onClose: () => void }) {
  const {
    data: lessonValidations,
    refetch: refetchValidations,
    isFetching: loadingValidations,
    error: fetchValidationError,
  } = useLessonValidateQuery(lessonId);
  const { isPending: isPublishPending, error: publishLessonError, mutate: publishLesson } = usePublishLesson();
  const [visibility, setVisibility] = useState<PublishVisibility>(PublishVisibility.PUBLIC);

  function publishAndCloseDialog() {
    if (lessonId) {
      publishLesson(
        { lessonId, payload: { visibility } },
        {
          onSuccess: _data => {
            onClose();
          },
        },
      );
    }
  }

  const failingValidations =
    lessonValidations?.filter(
      validation => !validation.pass && validation.name !== ValidationErrors.UNNORMALIZED_VIDEOS,
    ) || [];
  const hasFailingValidations = failingValidations.length > 0;
  useEffect(() => {
    refetchValidations?.();
  }, [refetchValidations]);

  return (
    <>
      <Typography variant='bodyMedium' mb={2}>
        Determine who can see and assign this lesson. You can change this lesson’s visibility at any time from Settings.
      </Typography>
      <PublishVisibilityOptions visibility={visibility} onUpdate={setVisibility} />

      {fetchValidationError && (
        <Box mt={2}>
          <Alert severity='error'>{fetchValidationError.message}</Alert>
        </Box>
      )}

      {publishLessonError && (
        <Box mt={2}>
          <Alert severity='error'>{publishLessonError.message}</Alert>
        </Box>
      )}

      {hasFailingValidations && (
        <Stack gap={1}>
          <Divider sx={{ my: 2 }} />
          <Typography variant='titleMedium'>Validation Errors:</Typography>
          <Typography variant='bodyMedium'>
            We have detected the following errors in your lesson. Please fix them in order to publish.
          </Typography>
          {failingValidations?.map(validation => (
            <Tooltip key={validation.name} title={validation.message}>
              <Alert key={validation.name} severity='error'>
                <Stack gap={1}>
                  <AlertTitle sx={{ m: 0 }}>{validation.name}</AlertTitle>
                  {validation.name === ValidationErrors.MISSING_VIDEOS ? (
                    <GenerateMissingVideosButton lessonId={lessonId} />
                  ) : null}
                </Stack>
              </Alert>
            </Tooltip>
          ))}
        </Stack>
      )}

      <DialogActions sx={{ pr: 0, pb: 1 }}>
        <Button variant='outlined' disabled={isPublishPending} onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={publishAndCloseDialog}
          disabled={isPublishPending || loadingValidations || hasFailingValidations}
          startIcon={isPublishPending || loadingValidations ? <LoadingIndicator spinnerSize={20} /> : null}
        >
          Publish
        </Button>
      </DialogActions>
    </>
  );
}

export function PublishStudioLessonButton({ lessonId, disabled }: { lessonId?: number; disabled?: boolean }) {
  const { openModal, closeModal } = useModal();

  function openPublishModal() {
    openModal({
      id: 'publish-lesson-modal',
      title: 'Publish Lesson?',
      content: <PublishStudioLessonDialog lessonId={Number(lessonId)} onClose={() => closeModal()} />,
    });
  }

  return (
    <Button onClick={openPublishModal} disabled={disabled}>
      Publish Lesson
    </Button>
  );
}
