import React, { SVGAttributes } from 'react';

export function KyronLogo(props: SVGAttributes<SVGSVGElement>) {
  const { fill, height = '24px' } = props;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 780.29 106.49' fill={fill} height={height}>
      <g>
        <g>
          <g>
            <path d='m128.66,11.77c0-6.5-5.27-11.77-11.77-11.77s-11.77,5.27-11.77,11.77c0,4.34,2.35,8.11,5.84,10.16v58.43h-29.09c-6.95,0-13.2,3.05-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87h-18.64v11.88h18.64c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h40.97V21.9c3.47-2.05,5.81-5.81,5.81-10.14Z' />
            <path d='m17.79,76.24V17.57h29.09c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h17.33V5.69h-17.33c-6.95,0-13.2,3.04-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87H5.92v70.43c-3.53,2.03-5.92,5.83-5.92,10.2,0,6.5,5.27,11.77,11.77,11.77s11.77-5.27,11.77-11.77c0-4.29-2.31-8.04-5.74-10.09Z' />
          </g>
          <g>
            <path d='m161.27,54.5h-8.35v31.21h-11.67V14.6h11.67v29.05h8.28l20.25-29.05h14.37l-24.3,34.3,26.52,36.8h-14.5l-22.26-31.21Z' />
            <path d='m247.92,30.86l-25.2,55.85c-6.2,13.79-11.65,18.67-22.83,18.67h-5.57v-10.16h4.7c7.25,0,9.49-2.12,13.5-10.89l1.23-2.58-23.88-50.88h12.26l17.43,38.25,16.36-38.25h12Z' />
            <path d='m284.01,30.55v10.65h-5.95c-9.33,0-13.98,5.32-13.98,15.25v29.25h-11.28V30.86h7.63l1.91,7.49c3.92-5.2,9.31-7.79,16.8-7.79h4.88Z' />
            <path d='m313.38,87.04c-16.78,0-29.33-12.28-29.33-28.76s12.55-28.76,29.33-28.76,29.33,12.25,29.33,28.76-12.58,28.76-29.33,28.76h0Zm17.85-28.76c0-10.5-7.47-18-17.85-18s-17.85,7.5-17.85,18,7.47,18,17.85,18,17.85-7.5,17.85-18Z' />
            <path d='m377.54,29.61c12.83,0,20.84,9.56,20.84,23.07v33.03h-11.27v-30.73c0-9.96-4.33-15.25-12.58-15.25s-14.73,6.93-14.73,16.62v29.37h-11.28V30.86h7.86l2.19,7.65c4.15-5.53,10.92-8.9,18.98-8.9h0Z' />
            <path d='m445.34,77.35v8.36h-40.15V14.6h8.91v62.75h31.24Z' />
            <path d='m499.74,61.63h-46.76c1.36,10.16,8.92,16.83,19.31,16.83,7.59,0,13.69-3.55,17.08-9.47h9.11c-4.64,11.31-14.29,17.92-26.18,17.92-15.91,0-27.97-12.29-27.97-28.64s12.06-28.62,27.97-28.62,27.64,12.83,27.64,28.85c0,1.04-.09,2.08-.19,3.12Zm-27.46-23.8c-9.96,0-17.32,6.27-19.11,15.82h38.09c-1.85-9.76-9.28-15.82-18.99-15.82h0Z' />
            <path d='m562.26,30.86v54.85h-7.15l-.91-8.31c-5.06,5.9-12.62,9.51-21.52,9.51-16.22,0-28.39-12.29-28.39-28.63s12.17-28.63,28.39-28.63c8.97,0,16.58,3.63,21.63,9.61l1.08-8.41h6.87Zm-8.82,27.43c0-11.67-8.49-20.27-20.12-20.27s-20.22,8.72-20.22,20.27,8.6,20.27,20.22,20.27,20.12-8.6,20.12-20.27Z' />
            <path d='m597.34,30.57v8.17h-5.6c-10.04,0-15.69,6.51-15.69,17.17v29.8h-8.63V30.86h6.13l1.4,8.35c3.88-5.61,9.63-8.64,17.53-8.64h4.86Z' />
            <path d='m650.04,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z' />
            <path d='m656.05,11.46h9.35v9.9h-9.35v-9.9Zm.36,19.4h8.63v54.85h-8.63V30.86Z' />
            <path d='m719.83,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z' />
            <path d='m780.29,30.86v47.18c0,17.68-10.21,28.45-26.89,28.45-13.45,0-22.99-7-25.99-18.64h8.63c2.44,6.86,9.36,10.75,17.35,10.75,10.97,0,18.45-7.28,18.45-19.45v-3.35c-4.92,5.08-11.93,8.16-19.98,8.16-15.23,0-26.98-11.77-26.98-27.1s11.77-27.2,26.98-27.2c8.77,0,16.35,3.73,21.3,9.73l1.37-8.53h5.76Zm-27.5,44.83c10.99,0,18.96-8,18.96-18.84s-7.98-18.93-18.96-18.93-19.09,8.11-19.09,18.93,8.1,18.84,19.09,18.84h0Z' />
          </g>
        </g>
      </g>
    </svg>
  );
}
