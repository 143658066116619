import { useParams } from 'react-router-dom';
import { LessonSection } from 'controllers/types';
import { PayloadAndRequestParams, useKyronMutationV2 } from './kyronMutation';
import { useKyronQuery } from './kyronQuery';

type CreateLessonSectionPayload = {
  lessonId: number;
  questionType: string;
  lessonSection: Partial<LessonSection>;
};
export const useCreateSection = (lessonId: number) => {
  const { segmentId: currentSegmentId } = useParams<{ segmentId: string }>();

  return useKyronMutationV2<{ payload: CreateLessonSectionPayload }, LessonSection>(
    `/lessons/${lessonId}/lesson_sections`,
    {
      method: 'POST',
      invalidatesMultiple: [
        [`/lessons/${lessonId}/lesson_sections`],
        [`/lesson_segments/${currentSegmentId}`], // invalidate the currently-selected segment
      ],
    },
  );
};

export const useUpdateSection = () => {
  const { segmentId: currentSegmentId } = useParams<{ segmentId: string }>();

  return useKyronMutationV2(`/lesson_sections/:lessonSectionId`, {
    method: 'PUT',
    invalidatesMultiple: ({ lessonSectionId, lessonId }) => [
      [`/lessons/${lessonId}/lesson_sections`],
      [`/lessons/${lessonId}`],
      [`/lesson_sections/${lessonSectionId}`],
      [`/lesson_segments/${currentSegmentId}`], // invalidate the currently-selected segment
    ],
  });
};

export const useDeleteSection = () => {
  const { segmentId: currentSegmentId } = useParams<{ segmentId: string }>();

  return useKyronMutationV2<PayloadAndRequestParams, { lesson_sections: LessonSection[] }, Error>(
    `/lessons/:lessonId/lesson_sections/:lessonSectionId`,
    {
      method: 'DELETE',
      invalidatesMultiple: ({ lessonSectionId, lessonId }) => [
        [`/lessons/${lessonId}/lesson_sections`],
        [`/lesson_sections/${lessonSectionId}`],
        [`/lesson_segments/${currentSegmentId}`], // invalidate the currently-selected segment
      ],
    },
  );
};

export const useSectionQuery = (sectionId?: number | string) =>
  useKyronQuery<LessonSection>(`/lesson_sections/${sectionId}`, {
    queryKey: ['/lesson_sections', sectionId],
    enabled: !!sectionId,
    placeholderData: previousData => previousData,
  });
