import { useParams } from 'react-router-dom';
import { useKyronQuery, keepPreviousData } from './kyronQuery';
import { useKyronMutationV2, PayloadAndRequestParams } from './kyronMutation';
import {
  Lesson,
  LessonDetails,
  Pagination,
  LessonSegment,
  LessonSection,
  PublishVisibility,
  LessonValidation,
} from '../types';

export const useLessonsQuery = ({
  page = 1,
  perPage = 25,
  search,
}: { page?: number; perPage?: number; search?: string } = {}) => {
  const searchQueryStr = search ? `&q=${search}` : '';
  return useKyronQuery<{ lessons: Lesson[]; meta: Pagination }>(
    `/lessons?page=${page}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: [`/lessons`, { page, perPage, ...(search ? { search } : {}) }],
      placeholderData: keepPreviousData,
      enabled: true,
    },
  );
};

export const useGetReadyForFinalizeQuery = (id: number) =>
  useKyronQuery<{
    data: boolean;
  }>(`/lessons/${id}/all_videos_attached`);

export const useLessonSegmentsQuery = ({
  lessonId,
  search = '',
  page = 1,
  perPage = 25,
}: {
  lessonId?: number;
  search?: string;
  page?: number;
  perPage?: number;
}) =>
  useKyronQuery<{ lesson_segments: LessonSegment[]; meta: Pagination }>(
    `/lessons/${lessonId}/lesson_segments?page=${page}&per_page=${perPage}&q=${search}`,
    {
      queryKey: [`/lessons/${lessonId}/lesson_segments`, { page, perPage, search }],
      placeholderData: keepPreviousData,
      enabled: !!lessonId,
    },
  );

export const useCreateLessonSegment = () =>
  useKyronMutationV2<PayloadAndRequestParams & { lessonId: number }, LessonSegment>(`/lesson_segments`, {
    method: 'POST',
    invalidatesMultiple: ({ lessonId }: { lessonId: number }) => [
      [`/lessons/${lessonId}/lesson_segments`],
      [`/lessons/${lessonId}/lesson_sections`],
    ],
  });

export const useLessonDetailsQuery = (id?: number | string, disabled?: boolean) =>
  useKyronQuery<LessonDetails>(`/lessons/${id}/lesson_details`, { enabled: !!id && !disabled });

export const useLessonSectionsQuery = (id?: number | string, disabled?: boolean) =>
  useKyronQuery<{ lesson_sections: LessonSection[] }>(`/lessons/${id}/lesson_sections`, { enabled: !!id && !disabled });

export const useLessonQuery = (lessonId?: number | string) =>
  useKyronQuery<Lesson>(`/lessons/${lessonId}`, { enabled: !!lessonId });

/**
 * Fetches the lesson based on the lessonId URL param
 */
export const useLessonFromParams = () => {
  const { lessonId } = useParams();
  return useLessonQuery(Number(lessonId));
};

export const useUpdateLesson = () =>
  useKyronMutationV2(`/lessons/:lessonId`, {
    method: 'PUT',
    invalidates: ({ lessonId }: { lessonId: number }) => [`/lessons/${lessonId}`],
  });

export const useDeleteLesson = () =>
  useKyronMutationV2(`/lessons/:lessonId`, {
    method: 'DELETE',
    invalidates: ({ lessonId }: { lessonId: number }) => [`/lessons/${lessonId}`],
  });

export const usePublishLesson = () => {
  const publishRoute = `/lessons/:lessonId/publish`;
  return useKyronMutationV2<{ lessonId: number; payload?: { visibility: PublishVisibility } }>(publishRoute, {
    method: 'POST',
    invalidatesMultiple: ({ lessonId }: { lessonId: number }) => [
      [`/lessons/${lessonId}`],
      ['/lesson_collections/library'],
      ['/lesson_collections/marketplace'],
    ],
  });
};

export const useGetLatestDraftLessonQuery = (lessonId: number) => {
  const latestDraftRoute = `/lessons/${lessonId}/get_latest_draft_lesson`;
  return useKyronQuery<Lesson>(latestDraftRoute);
};

export const useLessonValidateQuery = (lessonId?: number) =>
  useKyronQuery<LessonValidation[]>(`/lessons/${lessonId}/validate`, {
    enabled: !!lessonId,
  });
