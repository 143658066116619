import { LessonSegment } from 'controllers/types';
import { useParams } from 'react-router-dom';
import { useKyronMutationV2 } from './kyronMutation';
import { useKyronQuery } from './kyronQuery';

export const useSegmentQuery = (segmentId?: number | string) =>
  useKyronQuery<LessonSegment>(`/lesson_segments/${segmentId}`, {
    enabled: !!segmentId,
    staleTime: 0, // We don't want to cache segment data as it can be updated by other segments moving, sections moving, etc.
    placeholderData: previousData => previousData,
  });

type LessonSegmentPayload = {
  forceRegenerate?: boolean;
} & LessonSegment;
export const useUpdateSegment = ({
  segmentId,
  sectionId,
  disableInvalidation,
}: {
  segmentId?: number | string;
  sectionId?: number | string;
  /**
   * We need disableInvalidation on this custom hook because this hook is being used with other mutations
   * and at some places we don't want to invalidate the data before other mutations are done. Otherwise, UI
   * flickers with obsolete data before the new data is fetched.
   */
  disableInvalidation?: boolean;
}) => {
  /**
   * Segment invalidation for updates is based on the url, so that when MC response segments are updated,
   * the corresponding wait segment is invalidated, as the responses are fetched as part of the wait segment.
   */
  const { segmentId: baseSegmentId } = useParams<{ segmentId: string }>();
  return useKyronMutationV2<{ lessonId: number; payload: LessonSegmentPayload }, LessonSegment>(
    `/lesson_segments/${segmentId}`,
    {
      method: 'PUT',
      invalidatesMultiple: disableInvalidation
        ? undefined
        : ({ lessonId }) => [
            [`/lesson_segments/${baseSegmentId}`],
            [`/lesson_sections`, sectionId],
            [`/lessons/${lessonId}`],
            // Invalidate lesson details to refresh background task statuses.
            // Without this, parts of UI that depends on lesson_details data might not react to the updates
            // i.e. the background task statuses
            [`/lessons/${lessonId}/lesson_details`],
          ],
    },
  );
};

type UpdateEdgePayload = {
  edge_position: number;
  answer: string;
  correctness_status: string;
  description: string;
};
export const useUpdateEdgeOfSegment = ({ segmentId, sectionId }: { segmentId: number; sectionId?: number }) =>
  useKyronMutationV2<{ payload: UpdateEdgePayload }, LessonSegment>(`/lesson_segments/${segmentId}/update_edge`, {
    method: 'PUT',
    invalidatesMultiple: [[`/lesson_segments/${segmentId}`], ['/lesson_sections', sectionId]],
  });

export const useDeleteSegment = () =>
  useKyronMutationV2(`/lesson_segments/:segmentId`, {
    method: 'DELETE',
    invalidates: ({ lessonId }) => [`/lessons/${lessonId}/lesson_sections`],
  });
