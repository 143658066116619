import { Box, Button, GlobalStyles, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import background from 'assets/contact_us_bg.jpg';
import texture from 'assets/texture.png';
import { KyronLogo } from 'components/KyronLogo';
import { Row } from 'components/Row/Row';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const HUBSPOT_CONTACT_US_FORM_ID = '7e1e1111-7ade-4f88-9e77-c007a921a840';
// Once AppContext is in place, we can move Hubpost Portal ID to the context
const HUBSPOT_PORTAL_ID = '24326849';

type ContactUsInputs = {
  firstName?: string;
  lastName?: string;
  company?: string;
  jobTitle?: string;
  workEmail?: string;
  message?: string;
};

export function ContactUs() {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState<ContactUsInputs>({});
  const portalId = HUBSPOT_PORTAL_ID;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!portalId) return;

    const path = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${HUBSPOT_CONTACT_US_FORM_ID}`;

    const body = {
      fields: [
        { type: '0-1', name: 'firstname', value: inputs.firstName },
        { type: '0-1', name: 'lastname', value: inputs.lastName },
        { type: '0-1', name: 'company', value: inputs.company },
        { type: '0-1', name: 'jobtitle', value: inputs.jobTitle },
        { type: '0-1', name: 'email', value: inputs.workEmail },
        { type: '0-1', name: 'additional_information_multi_line', value: inputs.message },
      ],
    };

    fetch(path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).then(() => {
      enqueueSnackbar('Thank you for your message! We will get back to you soon.', { variant: 'success' });
      setInputs({});
    });
  };

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center center' },
        }}
      />

      <Box
        sx={{
          backgroundImage: `url(${texture})`,
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          mixBlendMode: 'overlay',
        }}
      />

      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          maxWidth: '720px',
          bgcolor: 'white',
          height: '100vh',
          p: { sm: 10, xs: 3 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          overflowY: 'auto',
          gap: 6,
        }}
      >
        <Link to='/'>
          <KyronLogo fill='#4B35FF' />
        </Link>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Stack width='100%' gap={2}>
            <Typography variant='headlineLarge'>Contact Us</Typography>
            <Row gap={2} sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              <TextField
                label='First name'
                fullWidth
                required
                name='firstName'
                value={inputs.firstName || ''}
                onChange={handleChange}
              />
              <TextField
                label='Last name'
                fullWidth
                required
                name='lastName'
                value={inputs.lastName || ''}
                onChange={handleChange}
              />
            </Row>
            <Row gap={2} sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              <TextField
                label='Company'
                fullWidth
                required
                name='company'
                value={inputs.company || ''}
                onChange={handleChange}
              />
              <TextField
                label='Job title'
                fullWidth
                required
                name='jobTitle'
                value={inputs.jobTitle || ''}
                onChange={handleChange}
              />
            </Row>
            <Row gap={2} sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              <TextField
                label='Work email'
                type='email'
                fullWidth
                required
                name='workEmail'
                value={inputs.workEmail || ''}
                onChange={handleChange}
              />
            </Row>
            <Row gap={2} sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              <TextField
                label='Message'
                fullWidth
                required
                multiline
                rows={5}
                name='message'
                value={inputs.message || ''}
                onChange={handleChange}
              />
            </Row>
            <Row gap={2} sx={{ flexDirection: { sm: 'row', xs: 'column' } }}>
              <Button type='submit'>Submit</Button>
            </Row>
          </Stack>
        </form>
        <Typography variant='bodySmall' color='#1C1B1F8C'>
          © 2024 Kyron Learning. All rights reserved.
        </Typography>
      </Box>
    </>
  );
}
