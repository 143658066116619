import React, { useState, useCallback } from 'react';
import { IconButton, Menu } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

export function KyronMenu({
  children,
  menuIcon = <MoreHoriz />,
}: {
  children: React.ReactNode;
  menuIcon?: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Insert `closeMenu` function on each of the children of KyronMenu
  // so they can close the menu when clicked
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { closeMenu: handleCloseMenu } as never);
    }
    return child;
  });

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        aria-haspopup='true'
        aria-expanded={isOpen}
        aria-label='menu'
        data-testid='kyron-menu-button'
        tabIndex={0}
      >
        {menuIcon}
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleCloseMenu}>
        {childrenWithProps}
      </Menu>
    </>
  );
}
