import React from 'react';
import { Tabs, Tab, Stack, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { useFeatures } from 'components/FeaturesContext';

const title = 'Library';

function getActions(showPlaylist: boolean) {
  return (
    <>
      <Button to='/studio/lesson-builder' component={Link} startIcon={<Add />}>
        New Lesson
      </Button>
      {showPlaylist && (
        <Button to='/studio/library/playlists/new' component={Link} startIcon={<Add />}>
          New Playlist
        </Button>
      )}
    </>
  );
}

type HeaderContextType = {
  setTitle: (title: string) => void;
  setActions: (actions: React.ReactNode) => void;
};

export function LibraryLayout() {
  const { pathname } = useLocation();
  const pathRoot = '/studio/library';
  const { setTitle, setActions } = useOutletContext<HeaderContextType>();
  const { show_playlist: showPlaylist } = useFeatures();

  return (
    <Stack gap={4} mt={3}>
      <Tabs value={pathname}>
        <Tab label='Lessons' to='' value={pathRoot} component={Link} replace />
        <Tab
          label='Playlists'
          to='playlists'
          value={`${pathRoot}/playlists`}
          component={Link}
          replace
          disabled={!showPlaylist}
        />
      </Tabs>
      <Outlet context={{ setTitle, setActions }} />
    </Stack>
  );
}

export const useHeader = () => {
  const { show_playlist: showPlaylist } = useFeatures();
  useTitle(title);
  useActions(getActions(showPlaylist));
};
