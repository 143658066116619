import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import React from 'react';
import { Link } from 'react-router-dom';
import { Check, Close } from '@mui/icons-material';
import { useUserContext } from 'components/UserContext';
import { MarketingHeader } from 'components/MarketingHome/components/MarketingHeader';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';

export function Pricing() {
  const [showAnnual, setShowAnnual] = React.useState(false);
  const { user } = useUserContext();

  const annualLabel = (
    <>
      Annual
      <Typography variant='bodySmall' color='textSecondary'>
        Save up to 27%
      </Typography>
    </>
  );

  const features = [
    {
      field: 'publicLessons',
      label: 'Create unlimited public lessons',
    },
    {
      field: 'privateLessons',
      label: 'Create unlimited private lessons',
    },
    {
      field: 'collaborators',
      label: 'Invite collaborators to create content',
    },
    {
      field: 'assignments',
      label: 'Assign lessons to learners',
    },
    {
      field: 'learnerDashboard',
      label: 'Gain insights with learner dashboard',
    },
    {
      field: 'googleClassroom',
      label: 'Integrate with Google Classroom',
    },
    {
      field: 'lti',
      label: 'Integrate with any LMS via LTI',
    },
    {
      field: 'learnerLimit',
      label: 'Learner limit',
    },
    {
      field: 'support',
      label: 'Priority support',
    },
  ] as const;

  type FeatureFields = (typeof features)[number]['field'];
  type MappedLevelFields = {
    [key in FeatureFields]: string | boolean | number | React.ReactNode;
  };

  type Level = MappedLevelFields & {
    label: string;
    price: string;
    priceDetails?: string | React.ReactNode;
  };

  const levels: Level[] = [
    {
      label: 'Individual',
      price: 'Free',
      publicLessons: true,
      privateLessons: false,
      collaborators: false,
      assignments: true,
      googleClassroom: true,
      lti: false,
      learnerDashboard: true,
      learnerLimit: 'up to 120 learners',
      support: false,
    },
    {
      label: 'Team',
      price: showAnnual ? '$50' : '$5',
      priceDetails: showAnnual ? 'per learner / year' : 'per learner / month',
      publicLessons: true,
      privateLessons: true,
      collaborators: true,
      assignments: true,
      googleClassroom: true,
      lti: false,
      learnerDashboard: true,
      learnerLimit: 'up to 1,000 learners',
      support: false,
    },
    {
      label: 'Enterprise',
      price: showAnnual ? '$100' : '$10',
      priceDetails: showAnnual ? 'per learner / year' : 'per learner / month',
      publicLessons: true,
      privateLessons: true,
      collaborators: true,
      assignments: true,
      googleClassroom: true,
      lti: true,
      learnerDashboard: true,
      learnerLimit: 'unlimited',
      support: true,
    },
  ];

  const renderBooleanIcon = (value: boolean) => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {value ? <Check color='success' /> : <Close color='error' />}
    </Box>
  );

  return (
    <Box sx={{ bgcolor: 'surface.main', minHeight: '100vh' }}>
      {user ? <AppBar mainToolBarSx={{ bgcolor: 'transparent' }} /> : <MarketingHeader />}

      <Stack
        gap={6}
        alignItems='center'
        py={10}
        sx={{
          background: `radial-gradient(circle 80vh at center bottom, #F1ECF4FF, transparent)`,
        }}
      >
        <Stack gap={2} alignItems='center'>
          <Typography variant='displayMedium'>Pricing</Typography>
          <Typography variant='bodyLarge' color='text.secondary'>
            Create and assign AI-powered interactive lessons.
          </Typography>
        </Stack>

        <ToggleSwitch
          offLabel='Monthly'
          onLabel={annualLabel}
          handleOff={() => setShowAnnual(false)}
          handleOn={() => setShowAnnual(true)}
        />

        <Paper elevation={3} sx={{ p: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {levels.map(level => (
                  <TableCell key={level.label} align='center' sx={{ verticalAlign: 'top', paddingBottom: '16px' }}>
                    <Stack alignItems='center' px={3} gap={1}>
                      <Typography variant='titleMedium' color='secondary.main'>
                        {level.label}
                      </Typography>
                      <Stack alignItems='center'>
                        <Typography variant='headlineLarge' color='textPrimary'>
                          {level.price}
                        </Typography>
                        <Typography variant='bodyMedium' color='textSecondary'>
                          {level.priceDetails}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map(({ field, label }) => (
                <TableRow key={field}>
                  <TableCell>{label}</TableCell>
                  {levels.map(level => (
                    <TableCell key={level.label} align='center'>
                      {typeof level[field] === 'boolean' ? renderBooleanIcon(level[field] as boolean) : level[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Stack alignItems='center' gap={2}>
          <Stack alignItems='center' color='text.secondary'>
            <span>Are you a K12 institution? We offer special pricing for schools.</span>
            <span>Interested in discussing usage-based pricing?</span>
          </Stack>
          <Button component={Link} to='mailto:sales@kyronlearning.com'>
            Contact sales to learn more
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
