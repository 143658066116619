// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Grid displays correctly renders the grid correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.emotion-2 {
  color: #1C1B1FB3;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.emotion-2.Mui-focused {
  color: #4B35FF;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-2.Mui-error {
  color: #BA1A1A;
}

.emotion-3 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding-left: 14px;
}

.emotion-3.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-3:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-3:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-3.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-3.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-3.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #D6D5DD;
}

.emotion-5 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
  padding-left: 0;
}

.emotion-5::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:focus {
  outline: 0;
}

.emotion-5:invalid {
  box-shadow: none;
}

.emotion-5::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-5.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-5:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-5:-webkit-autofill {
  border-radius: inherit;
}

.emotion-6 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-7 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.emotion-7>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.emotion-8 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  max-width: 100%;
  font-family: Open Sans;
  font-size: 0.8125rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  color: #1C1B1F;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: unset;
  outline: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #bdbdbd;
}

.emotion-8::-moz-focus-inner {
  border-style: none;
}

.emotion-8.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-8 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-8.Mui-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.emotion-8 .MuiChip-avatar {
  margin-left: 5px;
  margin-right: -6px;
  width: 24px;
  height: 24px;
  color: #616161;
  font-size: 0.75rem;
}

.emotion-8 .MuiChip-avatarColorPrimary {
  color: #FFFFFF;
  background-color: rgb(52, 37, 178);
}

.emotion-8 .MuiChip-avatarColorSecondary {
  color: #FFFFFF;
  background-color: rgb(111, 0, 135);
}

.emotion-8 .MuiChip-avatarSmall {
  margin-left: 4px;
  margin-right: -4px;
  width: 18px;
  height: 18px;
  font-size: 0.625rem;
}

.emotion-8 .MuiChip-icon {
  margin-left: 5px;
  margin-right: -6px;
  color: #616161;
}

.emotion-8 .MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent;
  color: rgba(28, 27, 31, 0.26);
  font-size: 22px;
  cursor: pointer;
  margin: 0 5px 0 -6px;
}

.emotion-8 .MuiChip-deleteIcon:hover {
  color: rgba(28, 27, 31, 0.4);
}

.emotion-8:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-8.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-8:active {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-8.MuiChip-clickable:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.emotion-8.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-8 .MuiChip-avatar {
  margin-left: 4px;
}

.emotion-8 .MuiChip-avatarSmall {
  margin-left: 2px;
}

.emotion-8 .MuiChip-icon {
  margin-left: 4px;
}

.emotion-8 .MuiChip-iconSmall {
  margin-left: 2px;
}

.emotion-8 .MuiChip-deleteIcon {
  margin-right: 5px;
}

.emotion-8 .MuiChip-deleteIconSmall {
  margin-right: 3px;
}

.emotion-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 11px;
  padding-right: 11px;
  white-space: nowrap;
}

.emotion-10 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-17 {
  height: 75vh;
  margin-top: 10px;
}

.emotion-18 {
  height: 100%;
  width: 100%;
  --ag-header-background-color: #FCFAFD;
  --ag-header-foreground-color: #1C1B1F;
  --ag-borders-critical: none;
  --ag-border-color: #D6D5DD;
}

.emotion-19 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #BA1A1A;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.emotion-20 {
  margin: 0;
  color: #BA1A1A;
}

.emotion-22 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #1A7918;
}

.emotion-23 {
  margin: 0;
}

.emotion-24 {
  margin: 0;
  color: #1C1B1F;
}

.emotion-26 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  color: #E6D119;
}

<div>
    <div
      class="MuiStack-root emotion-0"
    >
      <div
        class="MuiFormControl-root MuiTextField-root emotion-1"
        data-testid="student-search"
      >
        <label
          class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeSmall MuiInputLabel-outlined emotion-2"
          data-shrink="true"
          for=":r0:"
          id=":r0:-label"
        >
          Search for students
        </label>
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall MuiInputBase-adornedStart emotion-3"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-4"
            data-testid="SearchIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
            />
          </svg>
          <input
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall MuiInputBase-inputAdornedStart emotion-5"
            id=":r0:"
            type="text"
            value=""
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-6"
          >
            <legend
              class="emotion-7"
            >
              <span>
                Search for students
              </span>
            </legend>
          </fieldset>
        </div>
      </div>
      <div
        class="MuiButtonBase-root MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-outlinedDefault emotion-8"
        data-testid="Finished"
        role="button"
        tabindex="0"
      >
        <span
          class="MuiChip-label MuiChip-labelMedium emotion-9"
        >
          Finished
        </span>
        <span
          class="MuiTouchRipple-root emotion-10"
        />
      </div>
      <div
        class="MuiButtonBase-root MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-outlinedDefault emotion-8"
        data-testid="In Progress"
        role="button"
        tabindex="0"
      >
        <span
          class="MuiChip-label MuiChip-labelMedium emotion-9"
        >
          In Progress
        </span>
        <span
          class="MuiTouchRipple-root emotion-10"
        />
      </div>
      <div
        class="MuiButtonBase-root MuiChip-root MuiChip-outlined MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-outlinedDefault emotion-8"
        data-testid="Misconceptions"
        role="button"
        tabindex="0"
      >
        <span
          class="MuiChip-label MuiChip-labelMedium emotion-9"
        >
          Misconceptions
        </span>
        <span
          class="MuiTouchRipple-root emotion-10"
        />
      </div>
    </div>
    <div
      class="MuiBox-root emotion-17"
    >
      <div
        aria-label="data-rows"
        class="ag-theme-material emotion-18"
      >
        <div
          style="height: 100%;"
        >
          <div
            class="ag-measurement-container"
          >
            <div />
            <div />
            <div />
          </div>
          <div
            aria-atomic="true"
            aria-live="polite"
            aria-relevant="additions text"
            class="ag-aria-description-container"
          />
          <!-- AG Grid -->
          <div
            class="ag-root-wrapper ag-ltr ag-layout-normal"
            grid-id="1"
            role="presentation"
          >
            <div
              class="ag-root-wrapper-body ag-focus-managed ag-layout-normal"
              role="presentation"
            >
              <div
                class="ag-tab-guard ag-tab-guard-top"
                role="presentation"
                tabindex="0"
              />
              <!-- AG Grid Body -->
              <div
                aria-colcount="6"
                aria-rowcount="3"
                class="ag-root ag-unselectable ag-layout-normal"
                role="treegrid"
              >
                <div
                  class="ag-header ag-pivot-off ag-header-allow-overflow"
                  role="presentation"
                  style="height: 33px; min-height: 33px;"
                >
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-header ag-hidden"
                    role="rowgroup"
                    style="width: 0px; min-width: 0px; max-width: 0px;"
                  />
                  <div
                    class="ag-header-viewport "
                    role="presentation"
                  >
                    <div
                      class="ag-header-container"
                      role="rowgroup"
                      style="width: 1050px;"
                    >
                      <div
                        aria-rowindex="1"
                        class="ag-header-row ag-header-row-column"
                        role="row"
                        style="height: 32px; top: 0px; width: 1050px;"
                      >
                        <div
                          aria-colindex="1"
                          aria-sort="none"
                          class="ag-header-cell ag-column-first ag-header-cell-sortable ag-focus-managed"
                          col-id="has_sensitive_input"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 50px; left: 0px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="true"
                            class="ag-header-cell-resize ag-hidden"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-5-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-5-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                />
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                        <div
                          aria-colindex="2"
                          aria-sort="none"
                          class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                          col-id="student"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 200px; left: 50px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="false"
                            class="ag-header-cell-resize"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-6-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-6-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                >
                                  Student
                                </span>
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                        <div
                          aria-colindex="3"
                          aria-sort="none"
                          class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                          col-id="status"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 200px; left: 250px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="false"
                            class="ag-header-cell-resize"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-7-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-7-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                >
                                  Status
                                </span>
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                        <div
                          aria-colindex="4"
                          aria-sort="none"
                          class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                          col-id="time_spent"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 200px; left: 450px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="false"
                            class="ag-header-cell-resize"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-8-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-8-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                >
                                  Time Spent
                                </span>
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                        <div
                          aria-colindex="5"
                          aria-sort="none"
                          class="ag-header-cell ag-header-cell-sortable ag-focus-managed"
                          col-id="finished_at"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 200px; left: 650px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="false"
                            class="ag-header-cell-resize"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-9-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-9-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                >
                                  Finished At
                                </span>
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                        <div
                          aria-colindex="6"
                          aria-sort="none"
                          class="ag-header-cell ag-column-last ag-header-cell-sortable ag-focus-managed"
                          col-id="misconceptions"
                          role="columnheader"
                          style="top: 0px; height: 32px; width: 200px; left: 850px;"
                          tabindex="-1"
                        >
                          <div
                            aria-hidden="false"
                            class="ag-header-cell-resize"
                            role="presentation"
                          />
                          <div
                            aria-hidden="true"
                            class="ag-labeled ag-label-align-right ag-checkbox ag-input-field ag-header-select-all ag-hidden"
                            role="presentation"
                          >
                            
                
                            <div
                              aria-hidden="true"
                              class="ag-input-field-label ag-label ag-hidden ag-checkbox-label"
                              data-ref="eLabel"
                              id="ag-10-label"
                              role="presentation"
                            />
                            
                
                            <div
                              class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                              data-ref="eWrapper"
                              role="presentation"
                            >
                              
                    
                              <input
                                aria-label="Press Space to toggle all rows selection (unchecked)"
                                class="ag-input-field-input ag-checkbox-input"
                                data-ref="eInput"
                                id="ag-10-input"
                                tabindex="-1"
                                type="checkbox"
                              />
                              
                
                            </div>
                            
            
                          </div>
                          <div
                            class="ag-header-cell-comp-wrapper"
                            role="presentation"
                          >
                            <div
                              class="ag-cell-label-container"
                              role="presentation"
                            >
                              
        
        
        
                              <div
                                class="ag-header-cell-label"
                                data-ref="eLabel"
                                role="presentation"
                              >
                                
            
                                <span
                                  class="ag-header-cell-text"
                                  data-ref="eText"
                                >
                                  Misconceptions
                                </span>
                                
            
                                <span
                                  aria-hidden="true"
                                  class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden"
                                  data-ref="eFilter"
                                >
                                  <span
                                    class="ag-icon ag-icon-filter"
                                    role="presentation"
                                    unselectable="on"
                                  />
                                </span>
                                
            
                                <!--AG-SORT-INDICATOR-->
                                <span
                                  class="ag-sort-indicator-container"
                                  data-ref="eSortIndicator"
                                >
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-order ag-hidden"
                                    data-ref="eSortOrder"
                                  />
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-ascending-icon ag-hidden"
                                    data-ref="eSortAsc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-asc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-descending-icon ag-hidden"
                                    data-ref="eSortDesc"
                                  >
                                    <span
                                      class="ag-icon ag-icon-desc"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden"
                                    data-ref="eSortMixed"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
        
                                  <span
                                    aria-hidden="true"
                                    class="ag-sort-indicator-icon ag-sort-none-icon ag-hidden"
                                    data-ref="eSortNone"
                                  >
                                    <span
                                      class="ag-icon ag-icon-none"
                                      role="presentation"
                                      unselectable="on"
                                    />
                                  </span>
                                  
    
                                </span>
                                
        
                              </div>
                              
    
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-header ag-hidden"
                    role="rowgroup"
                    style="width: 0px; min-width: 0px; max-width: 0px;"
                  />
                </div>
                <!-- AG Pinned Top -->
                <div
                  class="ag-floating-top ag-selectable"
                  role="presentation"
                  style="height: 0px; min-height: 0; display: none; overflow-y: hidden;"
                >
                  <!-- AG Row Container topLeft -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-floating-top ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container topCenter -->
                  <div
                    class="ag-floating-top-viewport"
                    role="presentation"
                  >
                    <div
                      class="ag-floating-top-container"
                      role="rowgroup"
                      style="width: 1050px;"
                    />
                  </div>
                  <!-- AG Row Container topRight -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-floating-top ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container topFullWidth -->
                  <div
                    class="ag-floating-top-full-width-container"
                    role="rowgroup"
                  />
                </div>
                <div
                  class="ag-body ag-layout-normal"
                  role="presentation"
                >
                  <!-- AG Middle -->
                  <div
                    class="ag-body-viewport ag-row-no-animation ag-layout-normal ag-selectable"
                    role="presentation"
                  >
                    <!-- AG Row Container left -->
                    <div
                      aria-hidden="true"
                      class="ag-pinned-left-cols-container ag-hidden"
                      role="rowgroup"
                      style="height: 84px; width: 0px; max-width: 0px; min-width: 0px;"
                    />
                    <!-- AG Row Container center -->
                    <div
                      class="ag-center-cols-viewport"
                      role="presentation"
                      style="height: 84px;"
                    >
                      <div
                        class="ag-center-cols-container"
                        role="rowgroup"
                        style="width: 1050px; height: 84px;"
                      >
                        <div
                          aria-rowindex="2"
                          class="ag-row-even ag-row-no-focus ag-row ag-row-level-0 ag-row-position-absolute ag-row-first ag-row-not-inline-editing"
                          role="row"
                          row-id="0"
                          row-index="0"
                          style="transform: translateY(0px); height: 42px;"
                          tabindex="-1"
                        >
                          <div
                            aria-colindex="1"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-first"
                            col-id="has_sensitive_input"
                            role="gridcell"
                            style="left: 0px; width: 50px; padding: 0px; display: flex; justify-content: center;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-has_sensitive_input-0"
                                role="presentation"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeMedium emotion-19"
                                  data-testid="sensitive-warning"
                                  focusable="false"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="2"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="student"
                            role="gridcell"
                            style="left: 50px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-student-1"
                                role="presentation"
                              >
                                <p
                                  class="MuiTypography-root MuiTypography-body1 emotion-20"
                                >
                                  student 1
                                </p>
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="3"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="status"
                            role="gridcell"
                            style="left: 250px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-status-2"
                                role="presentation"
                              >
                                <div
                                  class="MuiStack-root emotion-0"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="MuiSvgIcon-root MuiSvgIcon-colorSuccess MuiSvgIcon-fontSizeMedium emotion-22"
                                    data-testid="DoneIcon"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"
                                    />
                                  </svg>
                                  <p
                                    class="MuiTypography-root MuiTypography-body1 emotion-23"
                                  >
                                    Finished
                                  </p>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="4"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="time_spent"
                            role="gridcell"
                            style="left: 450px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-time_spent-3"
                                role="presentation"
                              >
                                2 days
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="5"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="finished_at"
                            role="gridcell"
                            style="left: 650px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-finished_at-4"
                                role="presentation"
                              >
                                1 day ago
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="6"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-last"
                            col-id="misconceptions"
                            role="gridcell"
                            style="left: 850px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-misconceptions-5"
                                role="presentation"
                              >
                                1 misconception
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          aria-rowindex="3"
                          class="ag-row-odd ag-row-no-focus ag-row ag-row-level-0 ag-row-position-absolute ag-row-last ag-row-not-inline-editing"
                          role="row"
                          row-id="1"
                          row-index="1"
                          style="transform: translateY(42px); height: 42px;"
                          tabindex="-1"
                        >
                          <div
                            aria-colindex="1"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-first"
                            col-id="has_sensitive_input"
                            role="gridcell"
                            style="left: 0px; width: 50px; padding: 0px; display: flex; justify-content: center;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-has_sensitive_input-6"
                                role="presentation"
                              />
                            </div>
                          </div>
                          <div
                            aria-colindex="2"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="student"
                            role="gridcell"
                            style="left: 50px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-student-7"
                                role="presentation"
                              >
                                <p
                                  class="MuiTypography-root MuiTypography-body1 emotion-24"
                                >
                                  student 2
                                </p>
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="3"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="status"
                            role="gridcell"
                            style="left: 250px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-status-8"
                                role="presentation"
                              >
                                <div
                                  class="MuiStack-root emotion-0"
                                >
                                  <svg
                                    aria-hidden="true"
                                    class="MuiSvgIcon-root MuiSvgIcon-colorWarning MuiSvgIcon-fontSizeMedium emotion-26"
                                    data-testid="TimelapseIcon"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M16.24 7.76C15.07 6.59 13.54 6 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"
                                    />
                                  </svg>
                                  <p
                                    class="MuiTypography-root MuiTypography-body1 emotion-23"
                                  >
                                    In Progress
                                  </p>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="4"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="time_spent"
                            role="gridcell"
                            style="left: 450px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-time_spent-9"
                                role="presentation"
                              >
                                2 days
                              </span>
                            </div>
                          </div>
                          <div
                            aria-colindex="5"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height"
                            col-id="finished_at"
                            role="gridcell"
                            style="left: 650px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-finished_at-10"
                                role="presentation"
                              />
                            </div>
                          </div>
                          <div
                            aria-colindex="6"
                            class="ag-cell ag-cell-not-inline-editing ag-cell-normal-height ag-column-last"
                            col-id="misconceptions"
                            role="gridcell"
                            style="left: 850px; width: 200px; white-space: pre; word-break: normal; overflow: auto;"
                            tabindex="-1"
                          >
                            <div
                              class="ag-cell-wrapper"
                              role="presentation"
                            >
                              <span
                                class="ag-cell-value"
                                id="cell-misconceptions-11"
                                role="presentation"
                              >
                                0 misconceptions
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- AG Row Container right -->
                    <div
                      aria-hidden="true"
                      class="ag-pinned-right-cols-container ag-hidden"
                      role="rowgroup"
                      style="height: 84px; width: 0px; max-width: 0px; min-width: 0px;"
                    />
                    <!-- AG Row Container fullWidth -->
                    <div
                      class="ag-full-width-container"
                      role="rowgroup"
                      style="height: 84px;"
                    />
                  </div>
                  <!-- AG Fake Vertical Scroll -->
                  <div
                    aria-hidden="true"
                    class="ag-body-vertical-scroll"
                  >
                    
            
                    <div
                      class="ag-body-vertical-scroll-viewport"
                      data-ref="eViewport"
                    >
                      
                
                      <div
                        class="ag-body-vertical-scroll-container"
                        data-ref="eContainer"
                        style="height: 84px;"
                      />
                      
            
                    </div>
                    
        
                  </div>
                </div>
                <!-- AG Sticky Top -->
                <div
                  class="ag-sticky-top ag-selectable"
                  role="presentation"
                  style="height: 0px; top: 33px; width: 100%;"
                >
                  <!-- AG Row Container stickyTopLeft -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-sticky-top ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container stickyTopCenter -->
                  <div
                    class="ag-sticky-top-viewport"
                    role="presentation"
                  >
                    <div
                      class="ag-sticky-top-container"
                      role="rowgroup"
                      style="width: 1050px;"
                    />
                  </div>
                  <!-- AG Row Container stickyTopRight -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-sticky-top ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container stickyTopFullWidth -->
                  <div
                    class="ag-sticky-top-full-width-container"
                    role="rowgroup"
                  />
                </div>
                <div
                  class="ag-sticky-bottom ag-hidden ag-selectable"
                  role="presentation"
                  style="height: 0px; bottom: 0px; width: 100%;"
                >
                  <!-- AG Row Container stickyBottomLeft -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-sticky-bottom ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container stickyBottomCenter -->
                  <div
                    class="ag-sticky-bottom-viewport"
                    role="presentation"
                  >
                    <div
                      class="ag-sticky-bottom-container"
                      role="rowgroup"
                      style="width: 1050px;"
                    />
                  </div>
                  <!-- AG Row Container stickyBottomRight -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-sticky-bottom ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container stickyBottomFullWidth -->
                  <div
                    class="ag-sticky-bottom-full-width-container"
                    role="rowgroup"
                  />
                </div>
                <!-- AG Pinned Bottom -->
                <div
                  class="ag-floating-bottom ag-selectable"
                  role="presentation"
                  style="height: 0px; min-height: 0; display: none; overflow-y: hidden;"
                >
                  <!-- AG Row Container bottomLeft -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-left-floating-bottom ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container bottomCenter -->
                  <div
                    class="ag-floating-bottom-viewport"
                    role="presentation"
                  >
                    <div
                      class="ag-floating-bottom-container"
                      role="rowgroup"
                      style="width: 1050px;"
                    />
                  </div>
                  <!-- AG Row Container bottomRight -->
                  <div
                    aria-hidden="true"
                    class="ag-pinned-right-floating-bottom ag-hidden"
                    role="rowgroup"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  <!-- AG Row Container bottomFullWidth -->
                  <div
                    class="ag-floating-bottom-full-width-container"
                    role="rowgroup"
                  />
                </div>
                <!-- AG Fake Horizontal Scroll -->
                <div
                  aria-hidden="true"
                  class="ag-body-horizontal-scroll"
                >
                  
            
                  <div
                    class="ag-horizontal-left-spacer"
                    data-ref="eLeftSpacer"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  
            
                  <div
                    class="ag-body-horizontal-scroll-viewport"
                    data-ref="eViewport"
                  >
                    
                
                    <div
                      class="ag-body-horizontal-scroll-container"
                      data-ref="eContainer"
                      style="width: 1050px;"
                    />
                    
            
                  </div>
                  
            
                  <div
                    class="ag-horizontal-right-spacer"
                    data-ref="eRightSpacer"
                    style="width: 0px; max-width: 0px; min-width: 0px;"
                  />
                  
        
                </div>
                <!-- AG Overlay Wrapper -->
                <div
                  class="ag-overlay ag-hidden"
                  role="presentation"
                >
                  
                
                  <div
                    class="ag-overlay-panel"
                    role="presentation"
                  >
                    
                    
                    <div
                      class="ag-overlay-wrapper ag-layout-normal ag-overlay-no-rows-wrapper"
                      data-ref="eOverlayWrapper"
                      role="presentation"
                      style="padding-top: 32px;"
                    />
                    
                
                  </div>
                  
            
                </div>
              </div>
              <div
                class="ag-tab-guard ag-tab-guard-bottom"
                role="presentation"
                tabindex="0"
              />
            </div>
            <div
              class="ag-paging-panel ag-unselectable ag-focus-managed"
              id="ag-11"
            >
              <div
                class="ag-tab-guard ag-tab-guard-top"
                role="presentation"
                tabindex="0"
              />
              
                
              <!--AG-PAGE-SIZE-SELECTOR-->
              <span
                class="ag-paging-page-size"
                data-ref="pageSizeComp"
              >
                <div
                  class="ag-picker-field ag-labeled ag-label-align-left ag-select"
                  role="presentation"
                >
                  
                
                  <div
                    aria-hidden="false"
                    class="ag-label"
                    data-ref="eLabel"
                    id="ag-15-label"
                  />
                  
                
                  <div
                    aria-controls="ag-select-list-16"
                    aria-expanded="false"
                    aria-label="Page Size"
                    class="ag-wrapper ag-picker-field-wrapper ag-picker-collapsed"
                    data-ref="eWrapper"
                    role="combobox"
                    tabindex="0"
                  >
                    
                    
                    <div
                      class="ag-picker-field-display"
                      data-ref="eDisplayField"
                      id="ag-15-display"
                    >
                      100
                    </div>
                    
                    
                    <div
                      aria-hidden="true"
                      class="ag-picker-field-icon"
                      data-ref="eIcon"
                    >
                      <span
                        class="ag-icon ag-icon-small-down"
                        role="presentation"
                        unselectable="on"
                      />
                    </div>
                    
                
                  </div>
                  
            
                </div>
              </span>
              
                
              <span
                class="ag-paging-row-summary-panel"
              >
                
                    
                <span
                  class="ag-paging-row-summary-panel-number"
                  data-ref="lbFirstRowOnPage"
                  id="ag-11-first-row"
                >
                  1
                </span>
                
                    
                <span
                  id="ag-11-to"
                >
                  to
                </span>
                
                    
                <span
                  class="ag-paging-row-summary-panel-number"
                  data-ref="lbLastRowOnPage"
                  id="ag-11-last-row"
                >
                  2
                </span>
                
                    
                <span
                  id="ag-11-of"
                >
                  of
                </span>
                
                    
                <span
                  class="ag-paging-row-summary-panel-number"
                  data-ref="lbRecordCount"
                  id="ag-11-row-count"
                >
                  2
                </span>
                
                
              </span>
              
                
              <span
                class="ag-paging-page-summary-panel"
                role="presentation"
              >
                
                    
                <div
                  aria-disabled="true"
                  aria-label="First Page"
                  class="ag-button ag-paging-button ag-disabled"
                  data-ref="btFirst"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="ag-icon ag-icon-first"
                    role="presentation"
                    unselectable="on"
                  />
                </div>
                
                    
                <div
                  aria-disabled="true"
                  aria-label="Previous Page"
                  class="ag-button ag-paging-button ag-disabled"
                  data-ref="btPrevious"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="ag-icon ag-icon-previous"
                    role="presentation"
                    unselectable="on"
                  />
                </div>
                
                    
                <span
                  class="ag-paging-description"
                >
                  
                        
                  <span
                    id="ag-11-start-page"
                  >
                    Page
                  </span>
                  
                        
                  <span
                    class="ag-paging-number"
                    data-ref="lbCurrent"
                    id="ag-11-start-page-number"
                  >
                    1
                  </span>
                  
                        
                  <span
                    id="ag-11-of-page"
                  >
                    of
                  </span>
                  
                        
                  <span
                    class="ag-paging-number"
                    data-ref="lbTotal"
                    id="ag-11-of-page-number"
                  >
                    1
                  </span>
                  
                    
                </span>
                
                    
                <div
                  aria-disabled="true"
                  aria-label="Next Page"
                  class="ag-button ag-paging-button ag-disabled"
                  data-ref="btNext"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="ag-icon ag-icon-next"
                    role="presentation"
                    unselectable="on"
                  />
                </div>
                
                    
                <div
                  aria-disabled="true"
                  aria-label="Last Page"
                  class="ag-button ag-paging-button ag-disabled"
                  data-ref="btLast"
                  role="button"
                  tabindex="0"
                >
                  <span
                    class="ag-icon ag-icon-last"
                    role="presentation"
                    unselectable="on"
                  />
                </div>
                
                
              </span>
              
            
              <div
                class="ag-tab-guard ag-tab-guard-bottom"
                role="presentation"
                tabindex="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
