import { Lesson } from 'controllers/types';
import { useKyronMutationV2 } from './kyronMutation';

export type StructuredLearningObjectives = string[];

export type GenerateQuestionsPayload = {
  lessonTitle: string;
  lessonObjective: string;
  audience: string;
  structuredLearningObjectives: StructuredLearningObjectives;
  signedMaterialIds: string[];
  questionType: string | null;
  activityTemplate: string;
  ttsVoiceId: string;
};

export const useStructureLearningObjectives = () =>
  useKyronMutationV2<{ payload: GenerateQuestionsPayload }, StructuredLearningObjectives>(
    `/simple_lessons/structure_learning_objectives`,
    {
      method: 'POST',
    },
  );

export const useGenerateEntireLesson = () =>
  useKyronMutationV2<{ payload: GenerateQuestionsPayload }, Lesson>(`/simple_lessons/generate_entire_lesson`, {
    invalidatesMultiple: [['/lessons'], ['/lesson_collections/library']],
  });

export const useRegenerateAllLessonVideos = () =>
  useKyronMutationV2('/lessons/:lessonId/regenerate_all_lesson_videos', {
    invalidatesMultiple: ({ lessonId, segmentId }) => [
      [`/lessons/${lessonId}/lesson_details`],
      [`/lesson_segments/${segmentId}`], // invalidate the currently-selected segment
    ],
  });
